import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import { Link } from "react-router-dom";
import Spinners from "components/Common/Spinner";

const List = ({ listData, setLoading }) => {
  const sectorBadgeClasses = sectorType => {
    switch (sectorType) {
      case "Francisco":
        return "badge badge-soft-success";
      case "João Victor":
        return "badge badge-soft-info";
      case "Karol":
        return "badge badge-soft-danger";
      default:
        return "badge badge-soft-warning";
    }
  };

  const sectorBadgeCampaigns = option => {
    switch (option) {
      case "SuperBet":
        return "badge badge-soft-info";
      case "Betano e SuperBet":
        return "badge badge-soft-success";
      case "Betano":
        return "badge badge-soft-danger";
      default:
        return "badge badge-soft-warning";
    }
  };

  return (
    <React.Fragment>
      <Row>
        {listData.length > 0 ? (
          listData.map((influencer, key) => (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span
                        className={
                          influencer.responsavel
                            ? sectorBadgeClasses(influencer.responsavel)
                            : "badge badge-soft-success"
                        }
                      >
                        {influencer.responsavel
                          ? influencer.responsavel
                          : "Nome indisponível"}
                      </span>

                      <span
                        style={{ marginLeft: "10px" }}
                        className={
                          influencer && influencer.campaign
                            ? sectorBadgeCampaigns(influencer.campaign)
                            : "badge badge-soft-success"
                        }
                      >
                        {influencer ? influencer.campaign : "Nome indisponível"}
                      </span>

                      {influencer &&
                        influencer.meta &&
                        influencer.FTDs >= influencer.meta && (
                          <span
                            style={{ marginLeft: "10px" }}
                            className="badge badge-soft-success"
                          >
                            Meta batida
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="text-center mb-3">
                    <img
                      src="https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg"
                      alt="Logo da viraw"
                      className="avatar-sm rounded-circle"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      {influencer?.influencer}
                    </h6>
                    <p className="mb-0 text-muted font-size-12">
                      @{influencer?.username}
                    </p>
                    <p className="mt-2 mb-1 text-muted font-size-12">
                      <strong>FTD Parcial:</strong>{" "}
                      {influencer?.FTDs?.toLocaleString("pt-BR")}
                    </p>
                    <p className="mt-1 mb-1 text-muted font-size-12">
                      <strong>Valor FTD:</strong>{" "}
                      {influencer?.valor?.toLocaleString("pt-BR")}
                    </p>

                    <p className="mb-1 text-muted font-size-12">
                      <strong>Valor retornado:</strong>{" "}
                      {influencer?.Pagar?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })}
                    </p>

                    <p className="mt-2 mb-2 text-muted font-size-12">
                      <strong>Valor antecipado:</strong>{" "}
                      {influencer?.advanceValue?.toLocaleString("pt-BR", {
                        style: "currency",
                        currency: "BRL",
                        minimumFractionDigits: 2,
                      })}
                    </p>
                  </div>

                  <Row>
                    <Col md="12">
                      <Link to={`/influencer/${influencer?.plugchat}`}>
                        <Button
                          style={{ width: "100%" }}
                          className="btn btn-soft-primary d-block"
                        >
                          Perfil
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          ))
        ) : (
          <Spinners setLoading={setLoading} />
        )}
      </Row>
    </React.Fragment>
  );
};

export default List;
