import React, { useEffect, useState, useContext } from "react"

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap"
import * as Yup from "yup"
import { useFormik } from "formik"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid"
import listPlugin from "@fullcalendar/list"
import interactionPlugin, { Draggable } from "@fullcalendar/interaction"
import BootstrapTheme from "@fullcalendar/bootstrap"
import DeleteModal from "./DeleteModal"
import ptBrLocale from "@fullcalendar/core/locales/pt-br"
import { useParams } from "react-router-dom"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { Link } from "react-router-dom"

import Toastr from "../../../components/Ui/Notifications";

// Import Breadcrumb
import Breadcrumbs from "../../../common/Breadcrumb"

// Import Images
import verification from "../../../assets/images/verification-img.png"
import Overview from "../InfluencerProfile/Overview"
import OverviewAdmin from "../../Admin/InfluencerProfile/Overview"
import OverviewExterno from "../../Externo/InfluencerProfile/Overview"
import { verificarImagem } from "utils/verificationImage"

import { Buttons, ButtonsTxt } from "./styles"
import WalletActivities from "./walletActivities"

const initialState = {
  events: [],
  categories: [],
}

const Calender = props => {
  document.title = "Calendar | Viraw Influencers ©"

  const { user } = useContext(AuthContext)
  const { id } = useParams()
  const [deleteId, setDeleteId] = useState(null)
  const [deleteModal, setDeleteModal] = useState(false) // Adicione esta linha

  const [influencer, setInfluencer] = useState([])
  const [influencerPhoto, setInfluencerPhoto] = useState([])

  const [modalcategory, setModalcategory] = useState(false) // Adicione esta linha
  const [selectedDay, setSelectedDay] = useState(null) // Adicione esta linha
  const [event, setEvent] = useState({})
  const [isEdit, setIsEdit] = useState(false)
  const [state, setState] = useState(initialState)
  const [plugchat, setPlugchat] = useState()
  const [posts, setPosts] = useState()
  const [selectedCategory, setSelectedCategory] = useState("false")
  const [plugchatAll, setPlugchatAll] = useState([])
  const [plugchatAllAdm, setPlugchatAllAdm] = useState([])
  const [indexInit, setIndexInit] = useState()
  const [indexInitAdm, setIndexInitAdm] = useState()
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedDates, setSelectedDates] = useState([]);
  const [currentDate, setCurrentDate] = useState('')
  const [trackings, setTracking] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const formatDate = (dateString) => {
    const months = [
      "Janeiro", "Fevereiro", "Março", "Abril", "Maio", "Junho",
      "Julho", "Agosto", "Setembro", "Outubro", "Novembro", "Dezembro"
    ];

    const weekDays = [
      "Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira",
      "Quinta-Feira", "Sexta-Feira", "Sábado"
    ];

    const dateParts = dateString.split('-');
    const year = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1;
    const day = parseInt(dateParts[2], 10);

    const date = new Date(year, month, day);

    const dayOfWeek = date.getDay();

    return `[ ${String(day).padStart(2, '0')} de ${months[month]} de ${year} ] - ${weekDays[dayOfWeek]}`;
  };

  useEffect(() => {
    getPlugChat()
  }, [user.token, id])

  async function getPlugChat() {
    await api
      .get(`/plugchat/plugchat/${id}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(response => {
        setInfluencer(response.data)
        setPlugchat(response.data)
        getTrackings(response.data.influencer_rent?.username)


        verificarImagem(response.data.influencer_rent?.photo)
          .then(isValid => {
            if (isValid) {
              setInfluencerPhoto(response.data.influencer_rent?.photo)
            } else {
              setInfluencerPhoto(
                "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg"
              )
            }
          })
          .catch(error => {
            console.error("Ocorreu um erro:", error)
          })

        setPosts(response.data.influencer_rent.posts)
      })
      .catch(error => {
        console.error(error)
      })
  }

  async function getTrackings(username){
    setIsLoading(true)
    const response = await api.get(`/tracking/superbet/${username}`,{
      headers:{
        Authorization: `Bearer ${user.token}`,
      }
    })
    setTracking(response.data?.Trackings)
    setIsLoading(false)
  }


  const categoryValidation = useFormik({
    enableReinitialize: true,
    initialValues: {
      title: (event && event.title) || "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Por favor, digitar o nome do evento"),
    }),
    onSubmit: values => {
      if (isEdit) {
        const updateEvent = {
          id: event.id,
          posted:
            selectedCategory === "aguardando"
              ? false
              : selectedCategory === "true"
                ? true
                : false,
          waiting: selectedCategory === "aguardando" ? true : false,
          title: values.title,
          start: event.start,
          className:
            selectedCategory === "aguardando"
              ? "bg-warning text-white"
              : selectedCategory === "true"
                ? "bg-success text-white"
                : "bg-danger text-white",
          influencer: plugchat.influencer_rent.id,
        }

        postEditPost(updateEvent)

        categoryValidation.resetForm()

        postEditPost(updateEvent)

        categoryValidation.resetForm()
      } else {
        const newEvent = {
          posted:
            selectedCategory === "aguardando"
              ? false
              : selectedCategory === "true"
                ? true
                : false,
          waiting: selectedCategory === "aguardando" ? true : false,
          title: values["title"],
          start: selectedDay ? selectedDay.date : new Date(),
          className:
            selectedCategory === "aguardando"
              ? "bg-warning text-white"
              : selectedCategory === "true"
                ? "bg-success text-white"
                : "bg-danger text-white",
        }

        postPost(newEvent)
        categoryValidation.resetForm()
      }
      toggle()
    },
  })

  const postEventsForMultipleDates = async () => {
    const baseEventData = {
      posted: selectedCategory === "true",
      waiting: selectedCategory === "aguardando",
      title: categoryValidation.values.title,
      className: selectedCategory === "aguardando"
        ? "bg-warning text-white"
        : selectedCategory === "true"
          ? "bg-success text-white"
          : "bg-danger text-white",
      influencer: plugchat.influencer_rent.id,
    };

    if (currentDate && selectedDates.length === 0) {
      const singleDateObj = new Date(currentDate);
      singleDateObj.setDate(singleDateObj.getDate() + 1);
      const adjustedDate = singleDateObj.toISOString().split('T')[0];

      const eventData = { ...baseEventData, start: adjustedDate };
      try {
        await postPost(eventData);
      } catch (error) {
        console.error("Erro ao enviar evento para a data:", adjustedDate, error);
      }
    } else {

      for (const date of selectedDates) {
        const dateObj = new Date(date);
        dateObj.setDate(dateObj.getDate() + 1);
        const adjustedDate = dateObj.toISOString().split('T')[0];

        const eventData = { ...baseEventData, start: adjustedDate };
        try {
          await postPost(eventData);
        } catch (error) {
          console.error("Erro ao enviar evento para a data:", adjustedDate, error);
        }
      }
    }

    setCurrentDate('');
    setSelectedDates([]);
    toggle();
  };


  async function postPost(data) {
    await api.post("/posts", {
      posted: data.posted,
      waiting: data.waiting,
      title: data.title,
      start: data.start,
      className: data.className,
      influencer: plugchat.influencer_rent.id,
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`,
      },
    })
      .then(response => {
        const newEvent = response.data;
        setPosts(prevPosts => [...prevPosts, newEvent]);
        Toastr("success", "Evento Adicionado!")
        getPlugChat();
      })
      .catch(error => {
        console.error(error);
      });
  }

  async function postEditPost(data) {
    if (user.typeName.type === "Externo") {
      Toastr("error", "Usuários Externos não têm permissão para editar eventos.");
      return;
    }
    await api
      .put(
        "/posts/update",
        {
          id: data.id,
          posted: data.posted,
          waiting: data.waiting,
          title: data.title,
          start: data.start,
          className: data.className,
          influencer: data.influencer,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        setPosts(substituirPorId(posts, data.id, data))
      })
      .catch(error => {
        console.error(error)
      })
  }

  async function postDelete(id) {
    if (user.typeName.type === "Externo") {
      Toastr("error", "Usuários Externos não têm permissão para excluir eventos.");
      return;
    }
    await api
      .delete("/posts/delete", {
        data: {
          id: id,
        },
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      .then(() => {
        setPosts(deletarPorId(posts, id))
        Toastr("sucess", "Evento Deletado!")
        setIsEdit(false)
        setModalcategory(false)
      })
      .catch(erro => {
        console.error(erro)
      })
  }

  function substituirPorId(arr, id, novoValor) {
    const index = arr.findIndex(item => item.id === id)

    if (index !== -1) {
      const novoArray = [...arr]
      novoArray[index] = { ...novoArray[index], ...novoValor }
      return novoArray
    }
    return arr
  }

  function deletarPorId(arr, id) {
    const novoArray = arr.filter(item => item.id !== id)
    return novoArray
  }

  useEffect(() => {
    new Draggable(document.getElementById("external-events"), {
      itemSelector: ".external-event",
    })
  }, [])

  const toggle = () => {
    if (user.typeName.type === "Externo") {
      return;
    }
    if (modalcategory) {
      setModalcategory(false);
      setEvent(null);
      setIsEdit(false);
      setSelectedDates([]);
    } else {
      setModalcategory(true);
      setShowDatePicker(false);
    }
  };

  const handleDateClick = arg => {
    const date = arg["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const clickedDate = arg.dateStr;

    if (!selectedDates.includes(clickedDate)) {
      setSelectedDates(prevDates => [...prevDates, clickedDate]);
    }

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )
    const modifiedData = { ...arg, date: modifiedDate }
    setCurrentDate(clickedDate);
    setSelectedDay(modifiedData)
    toggle()
  }

  const handleEventClick = arg => {
    const event = arg.event;
    const eventProps = event.extendedProps || {};
    const className = event.className || eventProps.className || "";

    let category = "false";
    if (className.includes("bg-warning")) {
      category = "aguardando";
    } else if (className.includes("bg-success")) {
      category = "true";
    }

    setEvent({
      id: event.id,
      title: event.title,
      start: event.start,
      className: className,
    });
    setDeleteId(event.id);
    setIsEdit(true);
    setSelectedCategory(category);

    toggle();
  };

  const handleDeleteEvent = () => {
    if (deleteId) {
      postDelete(deleteId)
    }
    setDeleteModal(false)
    setIsEdit(false)
  }

  const onDrag = event => {
    event.preventDefault()
  }

  const onDrop = event => {
    const date = event["date"]
    const day = date.getDate()
    const month = date.getMonth()
    const year = date.getFullYear()

    const currectDate = new Date()
    const currentHour = currectDate.getHours()
    const currentMin = currectDate.getMinutes()
    const currentSec = currectDate.getSeconds()
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    )

    const draggedEl = event.draggedEl
    const draggedElclass = draggedEl.className
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") == -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 100),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      }
      setState({ ...state, events: [...state.events, modifiedData] })
    }
  }

  const handleCategoryChange = event => {
    const selectedValue = event.target.value
    setSelectedCategory(selectedValue)
  }

  const next = option => {
    if (user.type === 1 || user.type === 4) {
      if (option === "next") {
        return plugchatAllAdm[indexInitAdm + 1]?.id
      } else {
        return plugchatAllAdm[indexInitAdm - 1]?.id
      }
    } else {
      if (option === "next") {
        return plugchatAll[indexInit + 1]?.id
      } else {
        return plugchatAll[indexInit - 1]?.id
      }
    }
  }

  const handleNextClick = () => {
    if (user.type === 1 || user.type === 4) {
      setIndexInitAdm(prevIndex => prevIndex + 1)
      if (indexInitAdm === plugchatAllAdm.length - 1) {
        setIndexInitAdm(0)
      } else {
        setIndexInitAdm(prevIndex => prevIndex + 1)
      }
    } else {
      setIndexInit(prevIndex => prevIndex + 1)
      if (indexInit === plugchatAll.length - 1) {
        setIndexInit(0)
      } else {
        setIndexInit(prevIndex => prevIndex + 1)
      }
    }
  }

  const handlePreviousClick = () => {
    if (user.type === 1 || user.type === 4) {
      setIndexInitAdm(prevIndex => prevIndex - 1)

      if (indexInitAdm <= 0) {
        setIndexInitAdm(plugchatAllAdm.length - 1)
      } else {
        setIndexInitAdm(prevIndex => prevIndex - 1)
      }
    } else {
      setIndexInit(prevIndex => prevIndex - 1)
      if (indexInit === 0) {
        setIndexInit(plugchatAll.length - 1)
      } else {
        setIndexInit(prevIndex => prevIndex - 1)
      }
    }
  }

  const handleAddDate = (date) => {
    if (!selectedDates.includes(date)) {
      setSelectedDates([...selectedDates, date]);
    }
  };

  const removeDate = (indexToRemove) => {
    setSelectedDates(selectedDates.filter((_, index) => index !== indexToRemove));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await postEventsForMultipleDates();

    const allDates = new Set([currentDate, ...selectedDates]);

    for (const date of allDates) {
      const event = {
        title: formValues.title,
        date: date,
      };
      try {
        await saveEvent(event);
      } catch (error) {
        console.error("Erro ao salvar evento:", event, error);
      }
    }
    toggle();
    setSelectedDates([]);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Calendar" breadcrumbItem="POSTS INFLUENCERS" />

          {
            influencer
            ?.influencer_rent
            ?.archiving === false ? (
              (user.type === 1 || user.type === 4) ?(
                <Buttons className="bg-light">''
                  <Link
                    to={`/influencer/${indexInitAdm <= 0
                      ? plugchatAllAdm[plugchatAllAdm.length - 1]?.id
                      : next("anterior")
                      }`}
                    onClick={handlePreviousClick}
                  >
                    <ButtonsTxt className="text-muted">🠔 Anterior</ButtonsTxt>
                  </Link>

                  <Link
                    to={`/influencer/${indexInitAdm === plugchatAllAdm.length - 1
                      ? plugchatAllAdm[0]?.id
                      : next("next")
                      }`}
                    onClick={handleNextClick}
                  >
                    <ButtonsTxt className="text-muted">Próximo 🠖</ButtonsTxt>
                  </Link>
                </Buttons>
              ) : (
                <Buttons className="bg-light">
                  <Link
                    to={`/influencer/${indexInit === 0
                      ? plugchatAll[plugchatAll.length - 1]?.id
                      : next("anterior")
                      }`}
                    onClick={handlePreviousClick}
                  >
                    <ButtonsTxt className="text-muted">🠔 Anterior</ButtonsTxt>
                  </Link>

                  <Link
                    to={`/influencer/${indexInit === plugchatAll.length - 1
                      ? plugchatAll[0]?.id
                      : next("next")
                      }`}
                    onClick={handleNextClick}
                  >
                    <ButtonsTxt className="text-muted">Próximo 🠖</ButtonsTxt>
                  </Link>
                </Buttons>
              )

            ) : null}

          <Row>
            <Col className="col-12">
              <Row>
              <Col xl={4}>
              <Card>
                {user.typeName.type === "Administrador" ? (
                  <OverviewAdmin
                    informations={influencer}
                    photo={influencerPhoto}
                  />
                ) : user.typeName.type === "Grupos" ? (
                  <Overview
                    informations={influencer}
                    photo={influencerPhoto}
                  />
                ) : (
                  <OverviewExterno
                    informations={influencer}
                    photo={influencerPhoto}
                  />
                )}
                <CardBody>
                  <Row className="justify-content-center mt-5"></Row>
                </CardBody>
              </Card>
            </Col>

                <Col xl={8}>

                {user.typeName.type !== "Externo" && (
                <div className="d-grid">
                    <Button
                      color="primary"
                      className="font-16 btn-block"
                      onClick={toggle}
                    >
                      <i className="mdi mdi-plus-circle-outline me-1" />
                      Criar um novo evento
                    </Button>
                  </div>
                  )}

                  <div id="external-events" className="mt-2">
                    {state.categories.map((category, i) => (
                      <div
                        className={`${category.type} external-event fc-event text-white`}
                        key={"cat-" + category.id}
                        draggable
                        onDrag={onDrag}
                      >
                        <i className="mdi mdi-checkbox-blank-circle font-size-11 me-2" />
                        {category.title}
                      </div>
                    ))}
                  </div>
                  <Card>
                    <CardBody>
                      <FullCalendar
                        plugins={[dayGridPlugin, interactionPlugin]}
                        initialView="dayGridMonth"
                        headerToolbar={{
                          left: "prev,next today",
                          center: "title",
                          right: "dayGridMonth,dayGridWeek,dayGridDay",
                        }}
                        locale={ptBrLocale}
                        buttonText={{
                          today: "Hoje",
                          month: "Mês",
                          week: "Semana",
                          day: "Dia",
                        }}
                        events={posts && posts}
                        editable={true}
                        droppable={true}
                        selectable={true}
                        dateClick={handleDateClick}
                        eventClick={handleEventClick}
                        drop={onDrop}
                      />
                    </CardBody>
                  </Card>

                  <div>
                  <WalletActivities  Trackings={trackings} isLoading={isLoading} setLoading={setIsLoading} />

                  </div>

                  <Modal
                    isOpen={modalcategory}
                    className={props.className}
                    centered
                  >
                    <ModalHeader toggle={toggle} tag="h5">
                      {!!isEdit ? "Editar Evento" : "Adicionar Evento"}
                    </ModalHeader>
                    <ModalBody className="p-4">
                      <Form onSubmit={e => {
                        e.preventDefault();

                        const eventData = {
                          posted: selectedCategory === "true",
                          waiting: selectedCategory === "aguardando",
                          title: categoryValidation.values.title,
                          className: selectedCategory === "aguardando"
                            ? "bg-warning text-white"
                            : selectedCategory === "true"
                              ? "bg-success text-white"
                              : "bg-danger text-white",
                          influencer: plugchat.influencer_rent.id,
                        };

                        if (isEdit) {
                          const updatedEvent = { ...eventData, id: event.id, start: event.start };

                          postEditPost(updatedEvent).then(() => {
                            toggle();
                          });
                        } else {
                          if (selectedDates.length > 0 || currentDate) {
                            postEventsForMultipleDates(eventData);
                          } else {
                            postPost({ ...eventData, start: new Date() }).then(() => {
                              toggle();
                            });
                          }
                        }
                      }}>
                        <Row>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Nome do Evento
                              </Label>
                              <Input
                                name="title"
                                type="text"
                                placeholder="Digite o nome do Evento"
                                onChange={categoryValidation.handleChange}
                                onBlur={categoryValidation.handleBlur}
                                value={categoryValidation.values.title || ""}
                                invalid={
                                  categoryValidation.touched.title &&
                                    categoryValidation.errors.title
                                    ? true
                                    : false
                                }
                              />
                              {categoryValidation.touched.title &&
                                categoryValidation.errors.title ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.title}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                          <Col className="col-12">
                            <div className="mb-3">
                              <Label className="form-label">
                                Categoria do Post
                              </Label>

                              <Input
                                type="select"
                                name="category"
                                placeholder="All Day Event"
                                onChange={handleCategoryChange}
                                value={selectedCategory}
                              >
                                <option value={"true"}>Postou</option>
                                <option value={"false"}>Não Postou</option>
                                <option value={"aguardando"}>Aguardando</option>
                              </Input>

                              <Col className="col-12 mt-4">
                                <Button color="primary" onClick={() => setShowDatePicker(!showDatePicker)}>
                                  + Adicionar em outras datas
                                </Button>
                              </Col>

                              {showDatePicker && (
                                <>
                                  <Col className="col-11 mt-3">
                                    <h6>Selecione as Datas</h6>
                                    <Input
                                      type="date"
                                      value={currentDate}
                                      onChange={(e) => {
                                        const newDate = e.target.value;
                                        if (newDate && !selectedDates.includes(newDate)) {
                                          setSelectedDates(prevDates => [...prevDates, newDate]);
                                        }
                                        // Não é necessário chamar setCurrentDate aqui, a menos que você tenha outro propósito para manter a "currentDate" atualizada.
                                      }}
                                    />
                                  </Col>
                                  <Col className="col-1 mt-3">
                                  </Col>
                                  <Col className="col-12 mt-2">
                                    <ul style={{ listStyleType: "disc", paddingLeft: "20px" }}>
                                      {selectedDates.map((date, index) => (
                                        <li key={index}>
                                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                                            {formatDate(date)}
                                            <button
                                              type="button"
                                              onClick={() => removeDate(index)}
                                              style={{ marginLeft: '5px', border: 'none', background: 'none', cursor: 'pointer' }}
                                            >
                                              <span style={{ color: 'red', fontWeight: 'bold' }}>X</span>
                                            </button>
                                          </div>
                                        </li>
                                      ))}
                                    </ul>
                                  </Col>
                                </>
                              )}

                              {categoryValidation.touched.category &&
                                categoryValidation.errors.category ? (
                                <FormFeedback type="invalid">
                                  {categoryValidation.errors.category}
                                </FormFeedback>
                              ) : null}
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2">
                          <Col className="col-6">
                            {isEdit && (
                              <div>
                                <button
                                  type="button"
                                  className="btn btn-danger"
                                  id="btn-delete-event"
                                  onClick={() => setDeleteModal(true)}
                                >
                                  Deletar
                                </button>
                                <DeleteModal
                                  show={deleteModal}
                                  onDeleteClick={handleDeleteEvent}
                                  onCloseClick={() => setDeleteModal(false)}
                                />
                              </div>
                            )}
                          </Col>

                          <Col className="col-6 text-end">
                            <button
                              type="button"
                              className="btn btn-light me-1"
                              onClick={toggle}
                            >
                              Cancelar
                            </button>
                            <button
                              type="submit"
                              className="btn btn-success"
                              id="btn-save-event"
                              onSubmit={handleSubmit}
                            >
                              Salvar
                            </button>
                          </Col>
                        </Row>
                      </Form>
                    </ModalBody>
                  </Modal>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Calender