//INSERIR TODAS AS ROTAS DO USUÁRIO QUE NÃO PRECISAM ESTAR LOGADO

import React from "react"
import { Routes, Route } from "react-router-dom"

//Contacts

import Dashboard from "../pages/Grupos/Dashboard/index"
import Influencers from "../pages/Grupos/InfluencerList"
import InfluencerProfile from "pages/Grupos/InfluencerProfile/index"
import Calendar from "pages/Grupos/Calendar/index"
import Arquivados from "pages/Grupos/InfluencerListArquivados"
import Ranking from "pages/Grupos/Ranking"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/influencers" element={<Influencers />} />
      {/* <Route index path="/influencer/:id" element={<InfluencerProfile />}  /> */}
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/influencer/:id" element={<Calendar />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/ranking" element={<Ranking />} />

      <Route path="*" element={<Dashboard />} />
    </Routes>
  )
}
