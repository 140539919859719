import React from "react"
import { Routes, Route } from "react-router-dom"

import Dashboard from "../pages/Admin/Dashboard/index"
import Influencers from "../pages/Admin/InfluencerList"
import InfluencersAll from "../pages/Admin/InfluencerListAll"
import Pendencias from "../pages/Admin/InfluencerListPendentes"
import Saques from "../pages/Admin/InfluencerListSaques"
import Arquivados from "../pages/Admin/InfluencerListArquivados"
import SemSucesso from "../pages/Admin/InfluencerListSemSucesso"
import Upload from "../pages/Admin/FormUpload/FormUpload"
import Tracking from "../pages/Admin/TrackingCode/index"
import Tracking2 from "../pages/Admin/TrackingCode2/invoices-list"
import TrackingPerfil from "../pages/Admin/TrackingPerfil"

import InfluencerProfile from "pages/Grupos/Calendar/index"
import ColaboradoresProfile from "pages/Admin/ColaboradoresProfile/ContactsProfile/contacts-profile"
import ColaboradoresProfiles from "pages/Admin/CandidateList"
import ColaboradorArquivado from "pages/Admin/ColaboradorArquivado"
import Contact from "pages/Admin/InfluencerListContact"
import DashboardJob from "pages/Admin/DashboardJob"
import MetricasColaborador from "pages/Admin/MetricasColaborador"
import GeneratePayments from "pages/Admin/ListGeneratePayments";
import Desarquivar from "pages/Admin/InfluencerListPendentesDesarquivar";
import AgendarSala from "pages/Admin/AgendarSala";
import DashboardSuperBet from "pages/Admin/DashboardSuperBet";
import ListGeneratePaymentsDay from "pages/Admin/ListGeneratePaymentsDay"
import ListGeneratePaymentsAntecipado from "pages/Admin/ListGeneratePaymentsAntecipado"
import InfluencerListNegotiating from "pages/Admin/InfluencerListNegotiating"
import Ranking from "pages/Admin/Ranking"

export default function AppRoutes() {
  return (
    <Routes>
      <Route index path="/" element={<Dashboard />} />
      <Route index path="/influencers" element={<Influencers />} />
      <Route index path="/influencers/all" element={<InfluencersAll />} />
      <Route index path="/pendentes" element={<Pendencias />} />
      <Route index path="/pendentes/desarquivar" element={<Desarquivar />} />
      <Route index path="/saques-influencers" element={<Saques />} />
      <Route index path="/arquivados" element={<Arquivados />} />
      <Route index path="/contato" element={<Contact />} />
      <Route index path="/semsucesso" element={<SemSucesso />} />
      <Route index path="/influencer/:id" element={<InfluencerProfile />} />
      <Route index path="/dashboard" element={<Dashboard />} />
      <Route index path="/form-uploads" element={<Upload />} />
      <Route index path="/tracking-codes" element={<Tracking />} />
      <Route index path="/tracking-codes2" element={<Tracking2 />} />
      <Route index path="/dashboard-aposta" element={<DashboardJob />} />
      <Route index path="/dashboard-super" element={<DashboardSuperBet />} />
      <Route index path="/generatepayments" element={<GeneratePayments />} />
      <Route index path="/agendar-sala" element={<AgendarSala />} />
      <Route index path="/generatepaymentsday" element={<ListGeneratePaymentsDay />} />
      <Route index path="/generatepaymentsantecipado" element={<ListGeneratePaymentsAntecipado />} />
      <Route index path="/negociando" element={<InfluencerListNegotiating />} />
      <Route index path="/ranking" element={<Ranking />} />
      <Route
        index
        path="/tracking-perfil/:username"
        element={<TrackingPerfil />}
      />
      {/* <Route index path="/colaboradores" element={<Colaboradores />}  /> */}
      <Route
        index
        path="/colaboradores/:id"
        element={<ColaboradoresProfile />}
      />
      <Route index path="/colaboradores" element={<ColaboradoresProfiles />} />
      {/* <Route index path="/colaboradores-metricas" element={<MetricasColaborador />}  /> */}
      <Route index path="/desativados" element={<ColaboradorArquivado />} />
      <Route path="*" element={<Dashboard />} />
    </Routes>
  )
}
