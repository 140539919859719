import React, { useEffect, useState, useContext } from 'react';
import { AuthContext } from 'contexts/auth';
import { Card, CardBody, Col, Container, Row, Collapse, Input, Button } from 'reactstrap';
import Select from 'react-select';
import api from 'config/api';
import Spinners from 'components/Common/Spinner';
import Breadcrumbs from 'components/Common/Breadcrumb';
import List from './List';
import Toastr from "../../../components/Ui/Notifications";

const CollaboratorsList = () => {
    document.title = 'Influencers | Viraw Influencers';

    const [isLoading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState(null);
    const { user } = useContext(AuthContext);
    const [searchValue, setSearchValue] = useState('');
    const [colaboradoresGrupo, setColaboradoresGrupo] = useState([]);
    const [listPayments, setListPayments] = useState([]);
    const [listPaymentsOriginal, setListPaymentsOriginal] = useState([]);
    const [loadingRequests, setLoadingRequests] = useState(false);

    const [isMonday, setIsMonday] = useState(false);

    useEffect(() => {
      const hoje = new Date();
      const diaSemana = hoje.getDay();
      setIsMonday(diaSemana === 1);

      getSaques();
    }, [user.token]);

    const getSaques = async () => {
        try {
          const response = await api.get('/influencers/generetepayments', {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          });

          setListPayments(response.data)
          setListPaymentsOriginal(response.data)

          let resp = [];

          response.data.forEach((item) => {
            if (!resp.includes(item.responsavel)) {
              resp.push(item.responsavel);
            }
          });


        setColaboradoresGrupo(resp);

     
        } catch (error) {
          console.error('Error fetching influencers:', error);
        }
      };
   
    const handleSectorChange = (selectedOption) => {
        setSelectedOption(selectedOption);
        if (selectedOption) {
            const selectedSectorValue = selectedOption.value;
            const filteredBySector = listPaymentsOriginal.filter(collaborator =>
                collaborator.responsavel === selectedSectorValue);
            setListPayments(filteredBySector);
        } else {
            setListPayments(listPaymentsOriginal);
        }
    };

    const handleSearchCollaborator = (value) => {

        setSearchValue(value);
        const lowerCaseValue = value.toLowerCase();
        
        const filteredCollaborators = listPaymentsOriginal.filter(collaborator => {
            const fullname = collaborator.influencer?.toLowerCase() || '';
            const username = collaborator.username?.toLowerCase() || '';
            return fullname.includes(lowerCaseValue) || username.includes(lowerCaseValue);
        });
        setListPayments(filteredCollaborators);

        if (value.trim() === '') {
            setSelectedOption(null);
            setListPayments(listPaymentsOriginal);
        }
    };

    const fetchPayments = () =>{
        setLoadingRequests(true)
        generatepayments()
    }

    const generatepayments = async () =>{
        await api.get('/influencers/requestpayments/all').then((response) =>{
            Toastr("success", "Solicitações realizadas com sucesso!")
            setLoadingRequests(false)

        }).catch((error) =>{
            Toastr("error", "Solicitações realizadas com sucesso!")
            setLoadingRequests(false)
        })
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Influencers" breadcrumbItem="Pagamentos da semana" />
                    <Row>
                        <Col lg={12}>
                            <Card className="collaborator-filter">
                                <CardBody>
                                    <form action="#">
                                        <Row className="g-3">

                                            <Col xxl={4} lg={4}>
                                                <div className="position-relative">
                                                    <Input
                                                        type="text"
                                                        id="searchCollaborator"
                                                        autoComplete="off"
                                                        placeholder="Buscar Influenciador"
                                                        onChange={(e) => handleSearchCollaborator(e.target.value)}
                                                        value={searchValue}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xxl={2} lg={3}>
                                                <div className="position-relative">
                                                    <Select
                                                        className="select2"
                                                        onChange={handleSectorChange}
                                                        options={colaboradoresGrupo.map(colaborador => ({
                                                            value: colaborador,
                                                            label: colaborador
                                                        }))}
                                                        value={selectedOption}
                                                        placeholder="Filtrar por responsável"
                                                        isClearable
                                                    />
                                                </div>
                                            </Col>

                                              
                                            <Col xxl={3} lg={3}>
                                            <div className="position-relative">
                                                <Button color='primary' onClick={()=> fetchPayments()}  disabled={!isMonday}>
                                                    {loadingRequests ? "Aguarde...": "Solicitar pagamentos"}
                                                </Button>

                                            </div>
                                            </Col>                                                                                                                   
                                        </Row>
                                    </form>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {isLoading ? <Spinners setLoading={setLoading} /> : <List listData={listPayments} />}
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CollaboratorsList;
