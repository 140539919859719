import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Button, Form, Label, Input, FormFeedback } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import logodark from "../../assets/images/logo-dark.png";
import logolight from "../../assets/images/logo-light.png";
import CarouselPage from "./CarouselPage";

const EsqueceuASenha2 = () => {
  // Título da página
  document.title = "Recuperar Senha | Skote - Template de Admin e Dashboard React";

  const validação = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Por favor, insira seu e-mail"),
    }),
    onSubmit: (values) => {
      // Adicione a lógica de envio de solicitação de redefinição de senha aqui
    }
  });

  return (
    <React.Fragment>
      <div>
        <Container fluid className="p-0">
          <Row className="g-0">
            <CarouselPage />

            <Col xl={3}>
              <div className="auth-full-page-content p-md-5 p-4">
                <div className="w-100">
                  <div className="d-flex flex-column h-100">
                    <div className="mb-4 mb-md-5">
                      <Link to="/dashboard" className="d-block auth-logo">
                        <img
                          src={logodark}
                          alt=""
                          height="18"
                          className="auth-logo-dark"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="18"
                          className="auth-logo-light"
                        />
                      </Link>
                    </div>
                    <div className="my-auto">
                      <div>
                        <h5 className="text-primary">Redefinir Senha</h5>
                        <p className="text-muted">Redefina sua senha.</p>
                      </div>

                      <div className="mt-4">
                        <div className="alert alert-success text-center mb-4" role="alert">
                          Insira seu e-mail e as instruções serão enviadas para você!
                        </div>

                        <Form
                          className="form-horizontal"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validação.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Insira seu e-mail"
                              type="email"
                              onChange={validação.handleChange}
                              onBlur={validação.handleBlur}
                              value={validação.values.email || ""}
                              invalid={
                                validação.touched.email && validação.errors.email ? true : false
                              }
                            />
                            {validação.touched.email && validação.errors.email ? (
                              <FormFeedback type="invalid">{validação.errors.email}</FormFeedback>
                            ) : null}
                          </div>
                          <div className="mt-3 text-end">
                            <Button
                              className="btn btn-primary w-md waves-effect waves-light"
                              type="submit"
                            >
                              Redefinir
                            </Button>
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p>
                            Lembrou?{" "}
                            <Link to="pages-login-2" className="fw-medium text-primary">
                              Faça login aqui
                            </Link>{" "}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mt-md-5 text-center">
                      <p className="mb-0">
                        © {new Date().getFullYear()} Viraw Plataform.{" "}

                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default EsqueceuASenha2;
