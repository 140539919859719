import React, { useState, useEffect, useContext } from "react"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import boardStyles from "./styles" // Ajuste o caminho conforme necessário
import parentContainer from "./styles"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { StateContext } from "contexts/states"
import ModalUpdatedInfluencer from "components/Modals/UpdatedInfluencer"
import ModalCreateInfluencer from "components/Modals/CreateInfluencer"
import Toastr from "../../../components/Ui/Notifications"
import { FaEdit, FaTrashAlt, FaSignOutAlt } from "react-icons/fa"
import { handleDeleteUser } from "../Contacts/ContactList/requests"
import FechadoModal from "../../../components/Modals/FechadoModal"
import ModalFechadoPayment from "components/Modals/FechadoPayment"
import ModalJustification from "components/Modals/SemSucesso"
import Remarketing from "components/Modals/Remarketing";
import Loading from "components/Ui/LoadingCircle"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Botao from './button/index'
// import io from "socket.io-client";
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import {
  Container,
  Row,
  Col,
  CardBody,
  Label,
  Input,
  Form,
  FormFeedback,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  UncontrolledTooltip,
} from "reactstrap"
import { race } from "redux-saga/effects"
import ModalCreateInfluencerCloser from "components/Modals/CreateInfluencerCloser"

function Board() {
  const { user } = useContext(AuthContext)
  const { modal, setModal } = useContext(StateContext)
  const { modalEdit, setModalEdit } = useContext(StateContext)
  const { modalDel, setModalDel } = useContext(StateContext)
  const [columns, setColumns] = useState({})
  const [status, setStatus] = useState([])
  const [influencers, setInfluencers] = useState([])
  const [contact, setContact] = useState()
  const [profileDataAvatar, setProfileDataAvatar] = useState()
  const [profileData, setProfileData] = useState()
  const [idDel, setIdDel] = useState()
  const [fechadoModalOpen, setFechadoModalOpen] = useState(false)
  const [selectedInfluencer, setSelectedInfluencer] = useState(null)
  const [selectedInfluencerId, setSelectedInfluencerId] = useState("")
  const [fecharModalPayment, setFecharModalPayment] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState()
  const [justificationModalOpen, setJustificationModalOpen] = useState(false);
  const [remarketingModalOpen, setRemarketingModalOpen] = useState(false);
  const [justification, setJustification] = useState("");
  const [remarketing, setRemarketing] = useState("");
  const [remarketingId, setRemarketingId] = useState("");
  const [loading, setLoading] = useState(true);
  const [showMonthPicker, setShowMonthPicker] = useState(false);



  // const socket = io("https://api-viraw-tm8e.onrender.com");
  //const socket = io("http://localhost:3333");

  const [filterKanban, setFilterKanban] = useState("weekly")

  // const allowedCaptureIds = ["eb8238bc-9974-40bb-a3ed-694074565db7", "91ea435f-e8d8-4092-9ee0-42d2106c1d93"];

  // // Função para verificar se o ID de captação atual está na lista de IDs permitidos
  // const isAllowedCaptureId = (id) => {
  //   return allowedCaptureIds.includes(id);
  // };

  const submitJustification = (justification) => {
    setJustificationModalOpen(true);
    postJustification(selectedInfluencer.influencerId, justification)
  };
  const submitRemarketing = (remarketing) => {
    setRemarketingModalOpen(true);
    postRemarketing(remarketingId, true, remarketing)
  };
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [filteredInfluencers, setFilteredInfluencers] = useState([]);


  async function postJustification(id, observation) {
    await api.put("/influencers/update/observation", {
      id: id,
      observation: observation,
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then((response) => {
      Toastr("success", "Justificado com sucesso!")

      updateInfluencers(response.data.id, 3);
      requestStatus("3", selectedInfluencer.influencerId)

    }).catch((error) => {
      console.error("Erro" + error)
    })
  }
  async function postRemarketing(id, remarketing, remarketing_reason) {
    try {
      await api.put(
        "/influencers/update/remarketing",
        {
          id: id,
          remarketing: remarketing,
          remarketing_reason: remarketing_reason,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      ).then(response => {
        Toastr("success", "Remarketing registrado com sucesso!");
        setInfluencers(prevInfluencers =>
          prevInfluencers.map(influencer =>
            influencer.id === id ? { ...influencer, remarketing: true, remarketing_reason: remarketing_reason } : influencer
          )
        );
      });
    } catch (error) {
      console.error("Erro" + error);
    }
  }
  const openFechadoModal = () => {
    setFechadoModalOpen(true)
  }
  const handleRemarketing = (id) => {
    setRemarketingId(id)
    setRemarketingModalOpen(!remarketingModalOpen)
  }
  const handleDeleteInfluencer = id => {
    setIdDel(id)
    setModalDel(true)
  }
  const handleDeleteUserConfirm = async () => {
    try {
      if (idDel) {
        await handleDeleteUser(idDel, user.token);
        setInfluencers(prevInfluencers =>
          prevInfluencers.filter(influencer => influencer.id !== idDel)
        );
      }
    } catch (error) {
      console.error("Erro ao excluir influenciador:", error);
    }
    setModalDel(false);
  };

  const updateInfluencers = (influencerId, newStatus) => {
    setInfluencers((prevInfluencers) =>
      prevInfluencers.map((influencer) =>
        influencer.id === influencerId ? { ...influencer, status: newStatus } : influencer
      )
    );
  };

  const handleFilterChange = (event) => {
    setFilterKanban(event.target.value);
    setShowMonthPicker(event.target.value === "monthly");
  };

  // useEffect(() => {
  //   socket.on("new_influencer", (items) => {
  //     getInfluencers()
  //     Toastr("success", "Influenciador adicionado com sucesso!")
  //   });
  // }, []);


  useEffect(() => {
    async function getStatus() {
      try {
        const response = await api.get("/status", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const updatedStatus = [];
        response.data.forEach(status => {
          if (status.id !== 5) {
            updatedStatus.push(status);
          }
        });

        setStatus(updatedStatus);
      } catch (error) {
        console.error("Erro ao obter status:", error);
      }
    }

    getStatus();
    getInfluencers();
  }, [user.token, filterKanban]);


  const filterInfluencersByDate = (filterType) => {
    const currentDate = new Date();
    let filteredInfluencers = [];

    switch (filterType) {
      case "daily":
        filteredInfluencers = influencers.filter((influencer) => {
          const influencerDate = new Date(influencer.status_date);
          return (
            currentDate.getDate() === influencerDate.getDate() &&
            currentDate.getMonth() === influencerDate.getMonth() &&
            currentDate.getFullYear() === influencerDate.getFullYear()
          );
        });
        break;
      case "weekly":
        const currentDayOfWeek = currentDate.getDay();
        const daysSinceLastSunday = currentDayOfWeek === 0 ? 6 : currentDayOfWeek - 0;
        const oneWeekAgo = new Date(currentDate.getTime() - daysSinceLastSunday * 24 * 60 * 60 * 1000);
        filteredInfluencers = influencers.filter((influencer) => {
          const influencerDate = new Date(influencer.status_date);
          return influencerDate >= oneWeekAgo && influencerDate <= currentDate;
        });
        break;
      case "monthly":
        const firstDayOfSelectedMonth = new Date(selectedYear, selectedMonth, 1);
        const lastDayOfSelectedMonth = new Date(selectedYear, selectedMonth + 1, 0);
        filteredInfluencers = influencers.filter((influencer) => {
          const influencerDate = new Date(influencer.status === 4 ? influencer.plug_chat?.created_at : influencer.status_date);
          return influencerDate >= firstDayOfSelectedMonth && influencerDate <= lastDayOfSelectedMonth;
        });
        break;

      case "total":
      default:
        filteredInfluencers = influencers;
        break;
    }
    return filteredInfluencers;
  };
  useEffect(() => {
    const filtered = filterInfluencersByDate(filterKanban);
    setFilteredInfluencers(filtered);
  }, [filterKanban, selectedMonth, selectedYear, influencers]);

  async function getInfluencers() {
    try {
      const response = await api.get(`influencers/captador/${user.userId}`, {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      })
      async function updateFoto(){
        const influencersWithPhotos = await Promise.all(response.data.map(async influencer => {
          const photoUrl = await verificarImagem(influencer?.photo);
          return { 
            ...influencer, 
            photo: photoUrl 
          };       
        }));
        setInfluencers(influencersWithPhotos);
      }
      updateFoto();

      setInfluencers(response.data)
      setLoading(false)

    } catch (error) {
      console.error("Erro ao obter influenciadores:", error)
      setLoading(false)
    }
  }

  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: 'HEAD' });
  
      if (response.status === 200) {
        return photoUrl;
      } else {
        return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
      }
    } catch (error) {
      return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
    }
  }
  
  useEffect(() => {
    if (status && influencers && status.length > 0 && influencers.length > 0) {
      const initial = {};

      status.forEach(element => {
        const items = filteredInfluencers.filter(item => element.id === item.status)
          .map(item => ({
            id: item.id,
            phone: item.phone,
            email: item.email,
            fullname: item.fullname,
            photo: item.photo,
            username: item.username,
            status: item.status,
            following: item.following,
            followers: item.followers,
            remarketing: item.remarketing,
            method_contact: item.method_contact,
            created_at: item.created_at,
            captacao_influencer: item.captacao_influencer
              ? item.captacao_influencer.fullname
              : "",
          }));

        const column = {
          id: `${element.id}`,
          name: element.status,
          items: items,
        };

        initial[`column-${element.id}`] = column;
      });

      setColumns(initial);
    }
  }, [influencers, status, filteredInfluencers]);

  const requestStatus = async (idStatus, idInfluencer) => {
    const idStatusInt = parseInt(idStatus)
    try {
      setInfluencers(prevInfluencers =>
        prevInfluencers.map(influencer =>
          influencer.id === idInfluencer
            ? { ...influencer, status: idStatusInt }
            : influencer
        )
      )

      await api.put(
        "/influencers/update/status",
        {
          id: idInfluencer,
          status: idStatusInt,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
    } catch (error) {
      console.error("Erro ao atualizar status:", error)

      setInfluencers(prevInfluencers =>
        prevInfluencers.map(influencer =>
          influencer.id === idInfluencer
            ? { ...influencer, status: influencer.status }
            : influencer
        )
      )
    }
  }

  const Register = async response => {
    try {
      setModal(false)
    } catch (error) {
      console.error("Erro ao cadastrar influenciador:", error)
    }
  }

  function Updated() {
    Toastr("success", "Influencer Editado com sucesso!")
    setProfileData(null)
    setProfileDataAvatar(null)
    window.location.reload()
  }

  const toggle = () => {
    setModal(!modal)
  }
  const onDragEnd = result => {
    const { source, destination, draggableId } = result;

    if (!destination) return;

    const sourceColumnName = `column-${source.droppableId}`;
    const destinationColumnName = `column-${destination.droppableId}`;

    const isMovingFromFechado = sourceColumnName === "column-4";
    const isMovingToFechado = destinationColumnName === "column-4";
    if (isMovingToFechado) {
      const item = columns[sourceColumnName].items.find(item => item.id === draggableId);

      const telefoneCadastrado = item && item.phone;

      if (!telefoneCadastrado) {
        Toastr("error", "Antes de fechar, por favor, cadastre o número de telefone!");
        return;
      }
    }

    const isMovingFromSemSucesso = sourceColumnName === "column-3";
    const isMovingToSemSucesso = destinationColumnName === "column-3";

    if (isMovingFromFechado) {
      return;
    }
    if (isMovingFromSemSucesso) {
      return;
    }
    if (isMovingToFechado) {
      setSelectedInfluencer({
        influencerId: draggableId,
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });

      setSelectedInfluencerId(draggableId);
      setFechadoModalOpen(true);

    } else if (isMovingToSemSucesso) {

      setSelectedInfluencer({
        influencerId: draggableId,
        sourceId: source.droppableId,
        destinationId: destination.droppableId,
        sourceIndex: source.index,
        destinationIndex: destination.index,
      });

      setSelectedInfluencerId(draggableId);
      setJustificationModalOpen(true);

    } else {
      moveInfluencer(draggableId, source, destination);

      requestStatus(destination.droppableId, draggableId);
    }
  };

  const confirmMove = () => {
    setFechadoModalOpen(false)
    setSelectedInfluencer(null)
    setFecharModalPayment(true)
  }

  const confirmMoveFechar = () => {
    if (selectedInfluencer) {
      const newDestinationIndex = selectedInfluencer.destinationIndex
      moveInfluencer(
        selectedInfluencer.influencerId,
        {
          droppableId: selectedInfluencer.sourceId,
          index: selectedInfluencer.sourceIndex,
        },
        { droppableId: "4", index: newDestinationIndex }
      )

      requestStatus("4", selectedInfluencer.influencerId)
    }
  }

  const cancelMove = () => {
    if (selectedInfluencer) {
      moveInfluencer(
        selectedInfluencer.influencerId,
        {
          droppableId: selectedInfluencer.destinationId,
          index: selectedInfluencer.destinationIndex,
        },
        {
          droppableId: selectedInfluencer.sourceId,
          index: selectedInfluencer.sourceIndex,
        }
      )
    }
    setFechadoModalOpen(false)
    setSelectedInfluencer(null)
  }
  const moveInfluencer = (influencerId, source, destination) => {
    const start = columns[`column-${source.droppableId}`]
    const finish = columns[`column-${destination.droppableId}`]

    const newStartItems = Array.from(start.items)
    const newFinishItems =
      destination.droppableId === source.droppableId
        ? newStartItems
        : Array.from(finish.items)

    const [removedInfluencer] = newStartItems.splice(source.index, 1)

    if (source.droppableId === destination.droppableId) {
      newStartItems.splice(destination.index, 0, removedInfluencer)
    } else {
      newFinishItems.splice(destination.index, 0, removedInfluencer)
    }

    const newColumns = {
      ...columns,
      [`column-${source.droppableId}`]: { ...start, items: newStartItems },
    }

    if (destination.droppableId !== source.droppableId) {
      newColumns[`column-${destination.droppableId}`] = {
        ...finish,
        items: newFinishItems,
      }
    }

    setColumns(newColumns)
  }

  <FechadoModal
    isOpen={fechadoModalOpen}
    toggle={() => setFechadoModalOpen(!fechadoModalOpen)}
    onConfirm={confirmMove}
    onCancel={cancelMove}
  />


  const handleModalPayment = () => {
    setFecharModalPayment(!fecharModalPayment)
  }

  return (
    <>
      {fecharModalPayment && (
        <ModalFechadoPayment
          idInfluencer={selectedInfluencerId}
          open={fecharModalPayment}
          toogle={handleModalPayment}
          confirm={confirmMoveFechar}
          updateInfluencers={updateInfluencers}

        />
      )}
      {justificationModalOpen && (
        <ModalJustification
          isOpen={justificationModalOpen}
          toggle={() => setJustificationModalOpen(!justificationModalOpen)}
          onSubmit={submitJustification}
          justification={justification}
          setJustification={setJustification}
        />
      )}
      {remarketingModalOpen && (
        <Remarketing
          isOpen={remarketingModalOpen}
          toggle={() => setRemarketingModalOpen(!remarketingModalOpen)}
          onSubmit={submitRemarketing}
          justification={remarketing}
          setJustification={setRemarketing}
        />
      )}
      <DragDropContext onDragEnd={onDragEnd}>
        <div>
       <Botao />
          {modal && <ModalCreateInfluencerCloser Register={Register} contact={contact} />}
          {modalEdit && (
            <ModalUpdatedInfluencer
              profileData={profileData}
              profileDataAvatar={profileDataAvatar}
              contact={contact}
              Updated={Updated}
            />
          )}

          <Modal isOpen={modalDel} toggle={() => setModalDel(!modalDel)}>
            <ModalHeader tag="h4" toggle={() => setModalDel(!modalDel)}>
              Confirmar Exclusão
            </ModalHeader>
            <ModalBody>Você deseja excluir o influenciador?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setModalDel(!modalDel)}>
                Cancelar
              </Button>
              <Button color="danger" onClick={handleDeleteUserConfirm}>
                Excluir
              </Button>
            </ModalFooter>
          </Modal>


        </div>

        {
          loading ? (
            <Loading />
          ) : (
            <div style={boardStyles.container} >
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: "30px" }} >
                <label htmlFor="filter" style={{ marginRight: '10px', display: 'flex', alignItems: 'flex-end' }}>Filtrar por:</label>
                <select
                  id="filter"
                  value={filterKanban}
                  onChange={handleFilterChange}
                  style={{
                    padding: '5px',
                    borderRadius: '5px',
                    border: '1px solid #ccc',
                    fontSize: '16px',
                    outline: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <option value="daily">Dia</option>
                  <option value="weekly">Semana</option>
                  <option value="monthly">Mês</option>
                  <option value="total">Todos</option>
                </select>
                {showMonthPicker && (
                  <select value={selectedMonth} onChange={(e) => setSelectedMonth(Number(e.target.value))} style={{ marginLeft: '10px' }}>
                    {Array.from({ length: 12 }).map((_, index) => (
                      <option key={index} value={index}>{new Date(0, index).toLocaleString('default', { month: 'long' })}</option>
                    ))}
                  </select>

                )}
              </div>


              <div style={parentContainer}>

                <div style={boardStyles.board}>
                  {Object.entries(columns).map(([columnId, column]) => (
                    <Column
                      key={columnId}
                      column={column}
                      setProfileData={setProfileData}
                      setProfileDataAvatar={setProfileDataAvatar}
                      setContact={setContact}
                      setModalDel={setModalDel}
                      modalDel={modalDel}
                      handleDeleteInfluencer={handleDeleteInfluencer}
                      loading={loading}
                      setRemarketingModalOpen={setRemarketingModalOpen}
                      remarketingModalOpen={remarketingModalOpen}
                      handleRemarketing={handleRemarketing}
                    />
                  ))}
                </div>

              </div>
            </div>
          )
        }
      </DragDropContext>
      <FechadoModal
        isOpen={fechadoModalOpen}
        toggle={() => setFechadoModalOpen(!fechadoModalOpen)}
        onConfirm={() => {
          confirmMove()
          setFechadoModalOpen(false)
        }}
      />
    </>
  )
}

function Column({
  column,
  setProfileData,
  setProfileDataAvatar,
  setContact,
  setModalDel,
  modalDel,
  handleDeleteInfluencer,
  setRemarketingModalOpen,
  remarketingModalOpen,
  handleRemarketing
}) {
  return (
    <>
      <Droppable droppableId={column.id} className="bg-light">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={boardStyles.column}
            className="bg-light"
          >
            <h2 style={boardStyles.columnTitle} className="text-muted bg-light">
              {`${column.name} (${column.items.length})`}
            </h2>
            {column.items.map((item, index) => (

              <Card
                key={item.id}
                item={item}
                index={index}
                setProfileData={setProfileData}
                setProfileDataAvatar={setProfileDataAvatar}
                setContact={setContact}
                setModalDel={setModalDel}
                modalDel={modalDel}
                handleDeleteInfluencer={handleDeleteInfluencer}
                setRemarketingModalOpen={setRemarketingModalOpen}
                remarketingModalOpen={remarketingModalOpen}
                handleRemarketing={handleRemarketing}
              />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

    </>
  )
}

function Card({
  item,
  index,
  setProfileData,
  setProfileDataAvatar,
  setContact,
  setModalDel,
  modalDel,
  handleDeleteInfluencer,
  setRemarketingModalOpen,
  remarketingModalOpen,
  handleRemarketing
}) {
  const { modalEdit, setModalEdit } = useContext(StateContext)
  const [showMenu, setShowMenu] = useState(false)
  const [hoveredItem, setHoveredItem] = useState(null)

  function verificarFoto(itemPhoto) {
    fetch(itemPhoto)
      .then(response => {
        if (response.status === 200) {
          return itemPhoto;
        } else {
          return 'https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg';
        }
      })
      .catch(error => {
        return 'https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg';
      });
  }

  const toggleMenu = () => {
    setShowMenu(!showMenu)
  }

  const edit = () => {
    setModalEdit(!modalEdit)
  }

  const handleEdit = () => {
    if (item.status !== 4) {
      setContact(item);
      setProfileDataAvatar(item.photo);
      setProfileData({
        followers: item.followers,
        following: item.following,
      });
      setModalEdit(true);
    }
    setShowMenu(false);
  };

  const handleDelete = () => {

    if (item.status !== 4) {
      handleDeleteInfluencer(item.id);
    }
    setShowMenu(false);
  };


  const priorityStylePotencial =
    item.status === 1
      ? { ...boardStyles.cardPriority, backgroundColor: "blue" }
      : boardStyles.cardPriority
  const priorityStyleContato =
    item.status === 2
      ? { ...boardStyles.cardPriority, backgroundColor: "purple" }
      : boardStyles.cardPriority
  const priorityStyleNegociando =
    item.status === 3
      ? { ...boardStyles.cardPriority, backgroundColor: "red" }
      : boardStyles.cardPriority
  const priorityStyleFechado =
    item.status === 4
      ? { ...boardStyles.cardPriority, backgroundColor: "green" }
      : boardStyles.cardPriority

  function passou24Horas(dataFornecida) {
    var dataAtual = new Date();

    var diferencaMilissegundos = dataAtual - dataFornecida;
    var milissegundosEm24Horas = 24 * 60 * 60 * 1000;

    if (diferencaMilissegundos >= milissegundosEm24Horas) {
      return true;
    } else {
      return false;
    }
  }
  function passou48Horas(dataFornecida) {
    var dataAtual = new Date();

    var diferencaMilissegundos = dataAtual - dataFornecida;
    var milissegundosEm24Horas = 48 * 60 * 60 * 1000;

    if (diferencaMilissegundos >= milissegundosEm24Horas) {
      return true;
    } else {
      return false;
    }
  }

  const redirect = (link) => {
    window.open(`https://www.instagram.com/${link}`, '_blank');
  }

  return (
    <>
      <Draggable draggableId={item.id} index={index} className="bg-muted">
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...boardStyles.card,
              ...(snapshot.isDragging && boardStyles.cardDragging),
              ...provided.draggableProps.style,
            }}

          >
            <div style={boardStyles.cardHeader} className="bg-muted">
              {item.status === 1 ? (
                <span style={priorityStylePotencial}>
                  {item.priority}Em Contato
                </span>
              ) : null}
              {item.status === 2 ? (
                <span style={priorityStyleContato}>
                  {item.priority}Negociando
                </span>
              ) : null}
              {item.status === 3 ? (
                <span style={priorityStyleNegociando}>
                  {item.priority}Sem sucesso
                </span>
              ) : null}
              {item.status === 4 ? (
                <span style={priorityStyleFechado}>{item.priority}Fechado</span>
              ) : null}


              {
                item.status === 1 && passou48Horas(new Date(item.created_at)) ? (
                  <>
                    {
                      item.remarketing === true ? (
                        <button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: item.remarketing ? '#2E86C1' : '#E74C3C', // Azul: #2E86C1, Vermelho: #E74C3C
                            padding: "2px 6px",
                            borderRadius: "6px",
                            width: "40px",
                            marginLeft: "0px",
                            color: "#fff",
                            fontSize: "8px",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s",
                          }}
                          onClick={() => handleRemarketing(item.id)}>+ 48h</button>
                      ) : (
                        <button
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            backgroundColor: item.remarketing ? '#2E86C1' : '#E74C3C',
                            padding: "2px 6px",
                            borderRadius: "6px",
                            marginLeft: "0px",
                            width: "40px",
                            color: "#fff",
                            fontSize: "8px",
                            border: "none",
                            cursor: "pointer",
                            outline: "none",
                            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                            transition: "background-color 0.3s",
                          }}
                          onClick={() => handleRemarketing(item.id)}>+ 48h</button>
                      )
                    }
                  </>) : (item.status === 1 && passou24Horas(new Date(item.created_at)) ?
                    (<>
                      {
                        item.remarketing === true ? (
                          <button
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: item.remarketing ? '#2E86C1' : '#E74C3C',
                              padding: "2px 6px",
                              borderRadius: "6px",
                              width: "40px",
                              marginLeft: "0px",
                              color: "#fff",
                              fontSize: "8px",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              transition: "background-color 0.3s",
                            }}
                            onClick={() => handleRemarketing(item.id)}>+ 24h</button>
                        ) : (
                          <button
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              backgroundColor: item.remarketing ? '#2E86C1' : '#E74C3C', // Azul: #2E86C1, Vermelho: #E74C3C
                              padding: "2px 6px",
                              borderRadius: "6px",
                              marginLeft: "0px",
                              width: "40px",
                              color: "#fff",
                              fontSize: "8px",
                              border: "none",
                              cursor: "pointer",
                              outline: "none",
                              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                              transition: "background-color 0.3s",
                            }}
                            onClick={() => handleRemarketing(item.id)}>+ 24h</button>
                        )
                      }

                    </>) : null
                )
              }
              <span style={boardStyles.cardDate} className="bg-muted">{item.date}</span>
              <button style={boardStyles.cardMenuButton} onClick={toggleMenu}>
                &#x22EE;
              </button>
              {showMenu && (
                <div style={boardStyles.dropdownMenu}>
                  <div
                    style={
                      hoveredItem === "edit"
                        ? {
                          ...boardStyles.dropdownMenuItem,
                          ...boardStyles.dropdownMenuItemHover, // Aplica o estilo de hover
                        }
                        : boardStyles.dropdownMenuItem
                    }
                    onMouseEnter={() => setHoveredItem("edit")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={handleEdit}
                  >
                    <FaEdit /> Editar
                  </div>
                  <div
                    style={
                      hoveredItem === "delete"
                        ? {
                          ...boardStyles.dropdownMenuItem,
                          ...boardStyles.dropdownMenuItemHover, // Aplica o estilo de hover
                        }
                        : boardStyles.dropdownMenuItem
                    }
                    onMouseEnter={() => setHoveredItem("delete")}
                    onMouseLeave={() => setHoveredItem(null)}
                    onClick={handleDelete}
                  >
                    <FaTrashAlt /> Excluir
                  </div>
                </div>
              )}
            </div>

            <h3 style={boardStyles.cardTitle} className="text-truncate">{item.fullname}</h3>

            <div style={boardStyles.cardFooter}>
            <img
                src={item.photo}
                alt="Avatar"
                style={boardStyles.cardAvatar}
              />
              <button className="text-muted font-size-12 text-truncate" style={boardStyles.bntRedirect} onClick={() => redirect(item.username)}> <i className="bx bxl-instagram" /> {item.username}</button>
            </div>
          </div>
        )}
      </Draggable>


    </>
  )
}

export default Board