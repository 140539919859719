import React, { useEffect, useContext, useState } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Spinner } from "reactstrap"
import "./overview.css"
import {
  CardBodyColaborador,
  ImagePlugChat,
  CardBodyColaboradorInfo,
  ImageColaborador,
  Buttons,
  SidebarHeader,
  SidebarHeaderText,
  SidebarHeaderH3,
  SelectorGroup,
  divButtonEdit,
  Select,
  SalvarUpdate,
  ButtonEditar,
  ButtonsPayments,
} from "./styles"

import Toastr from "../../../components/Ui/Notifications"
import { BsPencilSquare } from "react-icons/bs" // Importe o ícone de lápis da biblioteca de ícones

import PlugChatImg from "../../../assets/images/plugchat.png"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import { StateContext } from "contexts/states"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlus, faSearch, faCopy } from "@fortawesome/free-solid-svg-icons"
import AddPaymentModal from "../../../components/Modals/AddPaymentModal" // Importe o componente AddPaymentModal
import PaymentDetailsModal from "../../../components/Modals/PaymentDetailsModal" // Importe o componente PaymentDetailsModal
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons"
import EditValuePayment from "../../../components/Modals/EditValuePayment"
import EditObservation from "../../../components/Modals/EditObservation"
import EditBankData from "../../../components/Modals/EditBankdata"
import EditMeta from "../../../components/Modals/EditMeta"

//import images
import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg"
import { verificarImagem } from "utils/verificationImage"

const Overview = ({ informations, photo }) => {
  const { user } = useContext(AuthContext)
  const {
    addValue,
    setValue,
    addValueSemanal,
    setAddValueSemanal,
    detailsValue,
    setDetailsValue,
  } = useContext(StateContext)
  const [copiado, setCopiado] = useState(false)
  const [copiadoApp, setCopiadoApp] = useState(false)
  const [roleta, setRoleta] = useState(false)

  const [showEditObservation, setShowEditObservation] = useState(false)
  const [observation, setObservation] = useState()

  const [showEditBankData, setShowEditBankData] = useState(false)
  const [bankData, setBankData] = useState()

  const [showEditMeta, setShowEditMeta] = useState(false)
  const [editMeta, setEditMeta] = useState()

  const [information, setInformations] = useState("")
  const [name, setName] = useState()
  const [pix, setPix] = useState()
  const [cpf, setCpf] = useState()
  const [type, setType] = useState()
  const [meta, setMeta] = useState(null)
  const [email, setEmail] = useState(null)

  const [buttonEdit, setButtonEdit] = useState(false)
  const [selecao, setSelecao] = useState("")
  const [userGroup, setUserGroup] = useState([])
  const [usersCaptacao, setUsersCaptacao] = useState([])
  const [loading, setLoading] = useState(true)
  const [newMeta, setNewMeta] = useState("")
  const [newValueMin, setNewValueMin] = useState("")
  const [newValueBonus, setNewValueBonus] = useState("")
  const [newDateInit, setNewDateInit] = useState("")
  const [betano, setBetano] = useState("")
  const [superBet, setSuperBet] = useState("")
  const [newDateFinal, setNewDateFinal] = useState("")

  const copiarTextoSuperbet = () => {
    const texto = `https://brsuperbet.link/${informations.influencer_rent?.username}`
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        setSuperBet(true)
        setTimeout(() => setCopiado(false), 2000)
      })
      .catch(err => {
        console.error("Erro ao copiar texto: ", err)
      })
  }
  const copiarTextoBetano= () => {
    const texto = `https://brbetano.link/${informations.influencer_rent?.username}`
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        setBetano(true)
        setTimeout(() => setCopiado(false), 2000)
      })
      .catch(err => {
        console.error("Erro ao copiar texto: ", err)
      })
  }

  const [responsavel, setResponsavel] = useState(
    informations.responsavel_rent?.fullname
  )
  const [captacao, setCaptacao] = useState(
    informations.influencer_rent?.captacao_influencer.fullname
  )
  const [selectedCaptado, setSelectedCaptado] = useState(
    informations.influencer_rent?.captacao_influencer.id
  )
  const [isEditingCaptado, setIsEditingCaptado] = useState(false)

  const handleEditObservationClick = () => {
    setShowEditObservation(true)
  }
  const handleEditMetaClick = () => {
    setShowEditMeta(true)
  }

  useEffect(() => {
    setInformations(informations.influencer_rent?.pix_bank.value)
    setObservation(informations.influencer_rent?.observation)
    setName(informations.influencer_rent?.pix_bank.name)
    setPix(informations.influencer_rent?.pix_bank.pix)
    setCpf(informations.influencer_rent?.pix_bank.cpf)
    setType(informations.influencer_rent?.pix_bank.type)
  }, [informations, bankData])

  useEffect(() => {}, [informations, editMeta])

  const onChangeValue = newValue => {
    setInformations(newValue)
  }

  const onChangeValueObservation = newValue => {
    setObservation(newValue)
  }

  const onChangeBankData = newValue => {
    setBankData(newValue)
    setName(newValue.name)
    setPix(newValue.pix)
    setCpf(newValue.cpf)
    setType(newValue.type)
  }
  const onChangeMeta = newValue => {}

  useEffect(() => {
    async function getUsers() {
      await api
        .get("users/all", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        })
        .then(response => {
          let usersGrupo = []
          let usersCaptacao = []
          response.data.forEach(element => {
            if (element.type_user.type === "Grupos") {
              usersGrupo.push(element)
            } else if (element.type_user.type === "Captação") {
              usersCaptacao.push(element)
            }
          })
          setUserGroup(usersGrupo)
          setUsersCaptacao(usersCaptacao)
        })
        .catch(error => {
          console.error("Erro " + error)
        })
    }

    getUsers()
  }, [user.token])

  async function postUpdatedResponsible() {
    await api
      .patch(
        "/plugchat/update/responsavel",
        {
          id: informations.id,
          responsavel: selecao,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        Toastr("success", "Responsável alterado!")
        const selectedUser = userGroup.find(user => user?.id === selecao)
        if (selectedUser) {
          setResponsavel(selectedUser)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  async function postUpdatedCaptado() {
    await api
      .patch(
        "/influencers/update/captacao",
        {
          id: informations.influencer_rent.id,
          captacao: selectedCaptado,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      .then(response => {
        Toastr("success", "Captador alterado!")

        const selectedUser = usersCaptacao.find(
          user => user?.id === selectedCaptado
        )
        if (selectedUser) {
          setCaptacao(selectedUser)
        }
      })
      .catch(error => {
        console.error(error)
      })
  }

  const handleAddPaymentClick = () => {
    setValue(true)
  }

  const handleEditValueClick = () => {
    setAddValueSemanal(true)
  }

  function redirect(link) {
    window.open(link, "_blank")
  }

  const handleSelecaoChange = event => {
    setSelecao(event.target.value)
  }

  const handleUpdatedChange = event => {
    setIsEditingCaptado(!isEditingCaptado)
  }
  const handleUpdatedChangeReponsavel = event => {
    setButtonEdit(!buttonEdit)
  }

  const handlePaymentDetailsClick = () => {
    setDetailsValue(true)
  }

  const toggleObservation = () => {
    setShowEditObservation(!showEditObservation)
  }

  const toggleEditBankData = () => {
    setShowEditBankData(!showEditBankData)
  }
  const toggleEditMeta = () => {
    setShowEditMeta(!showEditMeta)
  }

  const formatar = Intl.NumberFormat("en", {
    notation: "compact",
  })
  useEffect(() => {
    async function getMeta() {
      try {
        const response = await api.get(
          `/profile/influencer/meta/${informations.influencer_rent?.id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        setMeta(response.data)
        setLoading(false)
      } catch (error) {
        console.error("Erro ao buscar campanha:", error)
        setLoading(false)
      }
    }
    async function editarMeta(
      id,
      meta,
      valueMin,
      valueBonus,
      dateInit,
      dateFinal
    ) {
      try {
        const response = await api.put(
          `/profile/influencer/meta/update`,
          {
            id,
            meta,
            valueMin,
            valueBonus,
            dateInit,
            dateFinal,
          },
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        setEditMeta(response.data)
        setLoading(false)
      } catch (error) {
        console.error("Erro ao editar meta:", error)
        setLoading(false)
      }
    }
    async function getEmail() {
      try {
        const response = await api.get(
          `/profile/influencer/email/${informations.influencer_rent?.id}`,
          {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          }
        )
        setEmail(response.data)
        setLoading(false)
      } catch (error) {
        console.error("Erro ao buscar campanha:", error)
        setLoading(false)
      }
    }

    if (informations.influencer_rent?.id) {
      getMeta()
      getEmail()
    }
  }, [user.token, informations.influencer_rent?.id])

  function formatarData(dateString) {
    let date = new Date(dateString)

    let day = date.getUTCDate()
    let month = date.getUTCMonth() + 1
    let year = date.getUTCFullYear()

    day = day < 10 ? "0" + day : day
    month = month < 10 ? "0" + month : month

    return `${day}/${month}/${year}`
  }
  const campaignId = informations?.influencer_rent?.campaigns

  let casa

  switch (campaignId) {
    case "a7137b8e-0531-4a15-8543-15d47fb6cf27":
      casa = "Superbet"
      break
    case "df43c42f8-fe44-4361-882c-0a00efd8d3c2":
      casa = "Betano"
      break
    case "740a566f-a540-4519-b3b6-75d1ae042e47":
      casa = "Dois"
      break
    default:
      casa = "Unknown"
      break
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <SidebarHeader>
              <div>
                <div>
                  {showEditObservation && (
                    <EditObservation
                      id={informations.influencer_rent?.id}
                      observation={observation}
                      controlModal={showEditObservation}
                      toggle={toggleObservation}
                      onChangeValueObservation={onChangeValueObservation}
                    />
                  )}
                </div>

                <div>
                  {showEditBankData && (
                    <EditBankData
                      id={informations.influencer_rent?.pix_bank.id}
                      bankData={{
                        id: informations.influencer_rent?.pix_bank.id,
                        name: informations.influencer_rent?.pix_bank.name,
                        cpf: informations.influencer_rent?.pix_bank.cpf,
                        type: informations.influencer_rent?.pix_bank.type,
                        pix: informations.influencer_rent?.pix_bank.pix,
                      }}
                      name={name}
                      cpf={cpf}
                      type={type}
                      pix={pix}
                      controlModal={showEditBankData}
                      toggle={toggleEditBankData}
                      onChangeBankData={onChangeBankData}
                    />
                  )}
                </div>

                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                  className="photo-sidebar"
                  src={photo}
                  alt="foto de perfil influencer"
                />
              </div>

              <div>
                {showEditMeta && (
                  <EditMeta
                    id={informations.influencer_rent?.pix_bank.id}
                    editMeta={{
                      id: meta?.id,
                      meta: meta?.meta,
                      valueMin: meta?.valueMin,
                      valueBonus: meta?.valueBonus,
                      dateInit: meta?.dateInit,
                      dateFinal: meta?.dateFinal,
                    }}
                    meta={meta?.meta}
                    valueMin={meta?.valueMin}
                    valueBonus={meta?.valueBonus}
                    dateInit={meta?.dateInit}
                    dateFinal={meta?.dateFinal}
                    controlModal={showEditMeta}
                    toggle={toggleEditMeta}
                    onChangeMeta={onChangeMeta}
                  />
                )}
              </div>
              <SidebarHeaderText>
                <SidebarHeaderH3>
                  {informations.influencer_rent?.fullname}
                </SidebarHeaderH3>
                <h5>@{informations.influencer_rent?.username}</h5>
              </SidebarHeaderText>
              <div className="sidebar-header-seguidores">
                <h6>
                  Seguidores:{" "}
                  {formatar.format(informations.influencer_rent?.followers)}
                </h6>
                <h6>
                  Seguindo:{" "}
                  {formatar.format(informations.influencer_rent?.following)}
                </h6>
              </div>

              <div className="hstack gap-2">
                <Buttons
                  onClick={() => {
                    redirect(informations.linkPlug)
                  }}
                  className="btn btn-soft-success w-100"
                >
                  <i className="bx bxl-whatsapp"></i>
                </Buttons>

                {/* BOTÃO PLUGCHAT */}
                <Buttons
                  onClick={() => {
                    redirect(informations.link)
                  }}
                  className="btn btn-soft-primary w-100"
                >
                  <i className="bx bx-chat"></i>
                </Buttons>

                <Buttons
                  onClick={() =>
                    redirect(
                      `https://instagram.com/${informations.influencer_rent?.username}`
                    )
                  }
                  className="btn btn-soft-danger w-100"
                >
                  <i className="bx bxl-instagram"></i>
                </Buttons>
              </div>
            </SidebarHeader>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardBodyColaborador>
              <h5 className="fw-semibold">Captado por:</h5>

              <divButtonEdit>
                <ButtonEditar onClick={handleUpdatedChange}>
                  <BsPencilSquare style={{ color: "white" }} size={20} />
                </ButtonEditar>
              </divButtonEdit>

              <CardBodyColaboradorInfo>
                <ImageColaborador
                  src={
                    captacao
                      ? captacao.photo
                      : informations.influencer_rent?.captacao_influencer.photo
                  }
                  alt=""
                />
                <h5 className="fw-semibold">
                  {captacao
                    ? captacao.fullname
                    : informations.influencer_rent?.captacao_influencer
                        .fullname}
                </h5>
              </CardBodyColaboradorInfo>

              {isEditingCaptado ? (
                <>
                  <SelectorGroup>
                    <label className="fw-semibold" htmlFor="seletor">
                      Selecione um captador:
                    </label>
                    <Select
                      id="seletor"
                      value={selectedCaptado}
                      onChange={event => setSelectedCaptado(event.target.value)}
                    >
                      <option value="">Escolha uma opção</option>
                      {usersCaptacao.map(element => (
                        <option key={element.id} value={element.id}>
                          {element.fullname}
                        </option>
                      ))}
                    </Select>
                    <SalvarUpdate onClick={() => postUpdatedCaptado()}>
                      Salvar
                    </SalvarUpdate>
                  </SelectorGroup>
                </>
              ) : (
                <Link
                  to={`/colaboradores/${informations.influencer_rent?.captacao_influencer.id}`}
                ></Link>
              )}
            </CardBodyColaborador>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <CardBodyColaborador>
              <h5 className="fw-semibold">Responsável Plug Chat:</h5>

              <divButtonEdit>
                <ButtonEditar onClick={handleUpdatedChangeReponsavel}>
                  <BsPencilSquare style={{ color: "white" }} size={20} />
                </ButtonEditar>
              </divButtonEdit>

              <Link to={`/colaboradores/${informations.responsavel_rent?.id}`}>
                <CardBodyColaboradorInfo>
                  <ImageColaborador
                    src={
                      responsavel
                        ? responsavel.photo
                        : informations.responsavel_rent?.photo
                    }
                    alt=""
                  />
                  <h5 className="fw-semibold">
                    {responsavel
                      ? responsavel.fullname
                      : informations.responsavel_rent?.fullname}
                  </h5>
                </CardBodyColaboradorInfo>
              </Link>

              {buttonEdit && (
                <SelectorGroup>
                  <label className="fw-semibold" htmlFor="seletor">
                    Selecione uma responsável:
                  </label>
                  <Select
                    id="seletor"
                    value={selecao}
                    onChange={handleSelecaoChange}
                  >
                    <option value="">Escolha uma opção</option>
                    {userGroup.map(element => (
                      <option key={element.id} value={element.id}>
                        {element.fullname}
                      </option>
                    ))}
                  </Select>
                  <SalvarUpdate onClick={() => postUpdatedResponsible()}>
                    Salvar
                  </SalvarUpdate>
                </SelectorGroup>
              )}
            </CardBodyColaborador>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5 style={{ marginTop: "10px" }} className="fw-semibold">
              Links para divulgação
            </h5>
            {informations.influencer_rent?.campaigns ===
              "a7137b8e-0531-4a15-8543-15d47fb6cf27" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da Superbet</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoSuperbet}
                      >
                        { superBet? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {informations.influencer_rent?.campaigns ===
              "df43c42f8-fe44-4361-882c-0a00efd8d3c2" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da Betano</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoBetano}
                      >
                        {betano ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {informations.influencer_rent?.campaigns ===
              "740a566f-a540-4519-b3b6-75d1ae042e47" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da SuperBet</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoSuperbet}
                      >
                        {superBet ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                    <tr>
                      <th scope="col">Link da Betano</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoBetano}
                      >
                        {betano ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="d-flex align-items-center">
              <h5
                style={{ marginTop: "10px", marginRight: "10px" }}
                className="fw-semibold"
              >
                Dados Bancários
              </h5>
              <button onClick={toggleEditBankData} className="btn btn-link">
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </div>

            <div className="max-width-container table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Nome</th>
                    <td scope="col">
                      {informations.influencer_rent?.pix_bank.name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">CPF</th>
                    <td>{informations.influencer_rent?.pix_bank.cpf}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de chave</th>
                    <td>{informations.influencer_rent?.pix_bank.type}</td>
                  </tr>

                  <tr>
                    <th scope="row">Pix</th>
                    <td>{informations.influencer_rent?.pix_bank.pix}</td>
                  </tr>

                  <h5
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                    className="fw-semibold"
                  >
                    Financeiro
                    {informations.influencer_rent?.isPaymentFixed === false && (
                      <button onClick={toggleEditMeta} className="btn btn-link">
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    )}
                  </h5>

                  {loading ? (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Spinner />
                    </div>
                  ) : (
                    <>
                      {informations.influencer_rent?.isPaymentFixed === true ? (
                        <tr>
                          <th scope="row">Valor Semanal</th>
                          {addValueSemanal && (
                            <EditValuePayment
                              id={informations.influencer_rent.pix_bank.id}
                              onChangeValue={onChangeValue}
                            />
                          )}
                          <td>
                            <span className="badge badge-soft-info">
                              {information || ""}
                            </span>
                            <button
                              onClick={() => handleEditValueClick()}
                              className="btn btn-link btn-sm"
                            >
                              <FontAwesomeIcon icon={faPencilAlt} />
                            </button>
                          </td>
                        </tr>
                      ) : (
                        <>
                          <>
                            {informations.influencer_rent?.isCommercial ===
                              false && (
                              <tr>
                                <th scope="row">Meta Semanal</th>
                                <td>{meta?.meta || "R$ 0,00"}</td>
                              </tr>
                            )}
                            {informations.influencer_rent?.isCommercial ===
                            false ? (
                              <tr>
                                <th className="" scope="row">
                                  Valor FTD
                                </th>
                                <td>{meta?.valueMin || "R$ 0,00"}</td>
                              </tr>
                            ) : (
                              <tr>
                                <th className="" scope="row">
                                  Primeiros Depósitos
                                </th>
                                <td>
                                  {meta?.first_deposit === 0 || "R$ 0,00"}
                                </td>
                              </tr>
                            )}
                            {informations.influencer_rent?.isCommercial ===
                              false && (
                              <tr>
                                <th scope="row">Valor Excedente (Bônus)</th>
                                <td>{meta?.valueBonus || "R$ 0,00"}</td>
                              </tr>
                            )}
                            {informations.influencer_rent?.isCommercial ===
                              true && (
                              <tr>
                                <th scope="row">Ganho Parcial</th>
                                <td>
                                  {meta?.first_deposit * 0.04 || "R$ 0,00"}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th scope="row">Inicio da Meta</th>
                              <td>{formatarData(meta?.dateInit) || null}</td>
                            </tr>
                            <tr>
                              <th scope="row">Final da meta</th>
                              <td>{formatarData(meta?.dateFinal) || null}</td>
                            </tr>
                            
                            <tr>
                            <th scope="row">Pagamento Diário</th>
                            <td>{meta?.isDailyPayment === true ? 'Sim': 'Não'}</td>
                          </tr>

                            <tr>
                              <th scope="row">Pagamento antecipado</th>
                              <td>{meta?.isAdvanceValue === true ? "Sim": "Não" || null}</td>
                            </tr>
                         {
                        meta?.isAdvanceValue === true &&  
                          <tr>
                            <th scope="row">Valor antecipado</th>
                            <td>{meta?.advanceValue || "R$ 0,00"}</td>
                          </tr>
                         } 
                          </>
                        </>
                      )}
                    </>
                  )}
                    <tr>
                    <h6
                      style={{
                        textAlign: "left",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                      className="fw-semibold"
                    >
                      Pagamentos
                    </h6>
                    <td>
                      <div className="d-flex align-items-center">
                        <ButtonsPayments
                          onClick={() => handleAddPaymentClick()}
                          className="btn btn-soft-success w-50 me-2"
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ fontSize: "16px" }}
                          />
                        </ButtonsPayments>
                        <ButtonsPayments
                          onClick={() => handlePaymentDetailsClick()}
                          className="btn btn-soft-info w-50 me-2"
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ fontSize: "16px" }}
                          />
                        </ButtonsPayments>
                        {addValue && <AddPaymentModal idInfluencer={informations.influencer_rent?.id} />}
                        {detailsValue && <PaymentDetailsModal influencerId={informations.influencer_rent.id} />}
                      </div>
                    </td>

                  </tr>
                </tbody>
                <h5
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                  className="fw-semibold"
                >
                  Mais Informações
                </h5>
                <tr>
                  <th scope="row">Observações</th>
                  <td>
                    <div className="observation-wrapper">
                      <span className="badge badge-soft-info observation-text">
                        {observation}
                      </span>
                      <button
                        onClick={() => handleEditObservationClick()}
                        className="btn btn-link btn-sm edit-icon"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <div className="text-center">
              <img
                src={adobephotoshop}
                alt=""
                height="50"
                className="mx-auto d-block"
              />
              <h5 className="mt-3 mb-1">Contatos</h5>
              <p className="text-muted mb-0">Informações para contato</p>
            </div>

            <ul className="list-unstyled mt-4">
              <li>
                <div className="d-flex">
                  <i className="bx bxl-whatsapp text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Telefone</h6>
                    <p className="text-muted fs-14 mb-0">
                      {informations.influencer_rent?.phone}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-money text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Plataforma dos influencers</h6>
                    <p className="text-muted fs-10 mb-0">
                      <a href="https://virawinfluencers.com.br">
                        Plataforma dos influenciadores
                      </a>
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-mail-send text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email de Login</h6>
                    <p className="text-muted fs-14 mb-2">{email?.email}</p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bxs-lock-alt text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Senha de login</h6>
                    <p className="text-muted fs-14 mb-2">
                      <h5>viraw{informations.influencer_rent?.username}#123</h5>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4"></div>
          </CardBody>
        </Card>
      </Col>
    </React.Fragment>
  )
}

export default Overview
