import React, { useEffect, useState, useMemo, useContext } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Input,
  Button,
} from "reactstrap";
import axios from "axios";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { formatarParaReal } from "../../../utils/masks";
import CadastrarInfluencerManual from "components/Modals/CadastrarInfluencerManual";
import CardUser from "./card-user";
import CardWelcome from "./card-welcome";
import MiniWidget from "./mini-widget";
import MiniWidgetAlternar from "./mini-widget-alternar";
import Earning from "./earning";
import SalesAnalytics from "./sales-analytics";
import SalesAnalytics2 from "./admin2-analytics";
import RankingComponent from "./rankingCaptacao";
import RankingHunter from "./rankingHunter";
import RankingFTDs from "./rankingFtds";
import RankingFTDsAtendimento from "./rankingFtdsAtendimento";
import api from "config/api";
import { AuthContext } from "contexts/auth";
import ApexRevenue from "./ApexRevenue";
import Loading from "components/Ui/LoadingCircle";
import { StateContext } from "contexts/states";

const DashboardSaas = (props) => {
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [grafico1, setGrafico1] = useState({});
  const [grafico2, setGrafico2] = useState({});
  const [grafico3, setGrafico3] = useState({});
  const [grafico4, setGrafico4] = useState({
    instagram: 0,
    whatsapp: 0,
    youtube: 0,
    googleforms: 0,
  });
  const [grafico5, setGrafico5] = useState({
    contato: 0,
    negociando: 0,
    semSucesso: 0,
    fechados: 0,
  });
  const [grafico6, setGrafico6] = useState({});
  const [grafico7, setGrafico7] = useState({});
  const [grafico8, setGrafico8] = useState({});
  const [mostrarGastoTotal, setMostrarGastoTotal] = useState(false);
  const [metrics, setMetrics] = useState();
  const { modal, setModal } = useContext(StateContext);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [selectedMonthFormated, setSelectedMonthFormated] = useState();

  const saveToCache = (key, data) => {
    const timestamp = new Date().getTime();
    localStorage.setItem(key, JSON.stringify({ data, timestamp }));
  };

  const loadFromCache = (key, cacheDuration = 3600000) => {
    const cachedData = localStorage.getItem(key);
    if (cachedData) {
      const { data, timestamp } = JSON.parse(cachedData);
      const currentTime = new Date().getTime();
      if (currentTime - timestamp < cacheDuration) {
        return data;
      }
    }
    return null;
  };

  const handleMonthChange = (event) => {
    const selectedDate = event.target.value;
    const [ano, mes] = selectedDate.split("-");
    const data = new Date(parseInt(ano), parseInt(mes) - 1, 1);
    setSelectedMonth(event.target.value);
    setSelectedMonthFormated(data.getMonth());
  };

  const toggleGastoTotal = () => {
    setMostrarGastoTotal(!mostrarGastoTotal);
  };

  useEffect(() => {
    setLoading(true);

    const cachedData = loadFromCache(`dashboard-data-${user.userId}`);
    if (cachedData) {
      setGrafico1(cachedData.graphic1);
      setGrafico2(cachedData.graphic2);
      setGrafico3(cachedData.graphic3);
      setGrafico4(cachedData.graphic4);
      setGrafico5(cachedData.graphic5);
      setGrafico6(cachedData.graphic6);
      setGrafico7(cachedData.graphic7);
      setGrafico8(cachedData.graphic8);
      fetchMetrics();
      setLoading(false);
    } else {
      if (user.token && user.userId) {
        api
          .get(`graphics/admin/${user.userId}`, {
            headers: {
              Authorization: `Bearer ${user.token}`,
            },
          })
          .then((response) => {
            const data = response.data;
            setGrafico1(data.graphic1);
            setGrafico2(data.graphic2);
            setGrafico3(data.graphic3);
            setGrafico4(data.graphic4);
            setGrafico5(data.graphic5);
            setGrafico6(data.graphic6);
            setGrafico7(data.graphic7);
            setGrafico8(data.graphic8);
            fetchMetrics();
            saveToCache(`dashboard-data-${user.userId}`, data);
          })
          .catch((error) => {
            console.error("Erro ao carregar os Dados:", error);
            setErrorMessage("Erro ao carregar os Dados.");
            setLoading(false);
          });
      }
    }
  }, [user.token, user.userId]);

  const fetchMetrics = async () => {
    try {
      const response = await api.get("/users/collaborator/metrics", {
        headers: {
          Authorization: `Bearer ${user.token}`,
        },
      });
      setMetrics(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Erro ao carregar os métricos:", error);
      setLoading(false);
    }
  };

  function regraDeTres(valor1, valor2, resultado1, resultado2) {
    const constanteProporcionalidade = resultado1 / valor1;
    const valorDesconhecido = constanteProporcionalidade * valor2;
    return valorDesconhecido / 10;
  }

  function converterValorParaNumero(valorFormatado) {
    if (typeof valorFormatado !== "string") {
      return 0;
    }
    const valorSemFormatacao = valorFormatado
      .replace(/R\$\s?/, "")
      .replace(/\./g, "")
      .replace(",", ".");
    const valorNumerico = parseFloat(valorSemFormatacao);
    return valorNumerico;
  }

  function handleOpenCollaboratorModal() {
    setModal(true);
  }

  const relatorios = useMemo(
    () => [
      {
        icon: "bx bx-copy-alt",
        title: "Orçamento mensal",
        value: `${grafico2.orçamento}`,
        badgeValue: "100%",
        color: "success",
        desc: "",
      },
      {
        icon: "bx bx-archive-in",
        title: mostrarGastoTotal ? "Gasto Mensal" : "Gasto Total",

        value: mostrarGastoTotal
          ? selectedMonth
            ? grafico2.meses[selectedMonthFormated] !== undefined &&
              grafico2.meses[selectedMonthFormated] !== null
              ? `${formatarParaReal(grafico2.meses[selectedMonthFormated])}`
              : "R$ 0"
            : `${formatarParaReal(grafico2.meses[new Date().getMonth()])}`
          : `${grafico2.orçamentoGastoTotal}`,

        badgeValue: mostrarGastoTotal
          ? grafico2 &&
            `${regraDeTres(
              500000,
              1000,
              converterValorParaNumero(grafico2.orçamentoGasto)
            ).toFixed(2)}%`
          : null,
        color: mostrarGastoTotal ? "success" : "white",
        desc: mostrarGastoTotal
          ? "Orçamento total de gasto"
          : "Do orçamento mensal",
      },
      {
        icon: "bx bx-purchase-tag-alt",
        title: "Custo Médio",
        value: `${grafico2.custoMedioTotal}`,
        badgeValue: "",
        color: "white",
        desc: "Custo médio por Influencer",
      },
    ],
    [mostrarGastoTotal, grafico2, selectedMonth]
  );

  document.title = "Painel | Plataforma Viraw ©";

  return (
    <React.Fragment>
      {!loading ? (
        <div className="page-content">
          <Container fluid>
            <Breadcrumbs title="Dashboard" breadcrumbItem="Painel" />

            <CardUser
              total={grafico1.total}
              ativos={grafico1.ativos}
              archiveds={grafico1.arquivados}
              totalSeguidores={grafico8}
            />
  
            <Row>
              <CardWelcome />
  
              <Col xl="8">
                {mostrarGastoTotal && (
                  <Input
                    style={{ marginBottom: "10px" }}
                    value={selectedMonth}
                    onChange={handleMonthChange}
                    type="month"
                  />
                )}
                <Row>
                  <MiniWidget reports={[relatorios[0]]} />
  
                  <MiniWidgetAlternar
                    report={relatorios[1]}
                    onToggle={toggleGastoTotal}
                  />
  
                  <MiniWidget reports={[relatorios[2]]} />
                </Row>
              </Col>
            </Row>
  
            <Row>
              <Col md={6}>
                <Card>
                  <CardBody>
                    <CardTitle className="card-title">
                      Evolução de Influenciadores Fechados
                    </CardTitle>
                    <div id="revenue-chart">
                      <ApexRevenue
                        dataColors='["--bs-primary"]'
                        data={[
                          grafico3?.jan || 0,
                          grafico3?.fev || 0,
                          grafico3?.mar || 0,
                          grafico3?.abr || 0,
                          grafico3?.mai || 0,
                          grafico3?.jun || 0,
                          grafico3?.jul || 0,
                          grafico3?.ago || 0,
                          grafico3?.set || 0,
                          grafico3?.out || 0,
                          grafico3?.nov || 0,
                          grafico3?.dez || 0,
                        ]}
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <SalesAnalytics
                  instagram={grafico4?.instagram || 0}
                  whatsapp={grafico4?.whatsapp || 0}
                  youtube={grafico4?.youtube || 0}
                  googleforms={grafico4?.googleforms || 0}
                  dataColors='["--bs-primary", "--bs-success", "--bs-danger", "--bs-warning" ]'
                  dashboard={true}
                />
              </Col>
            </Row>
  
            <Row>
              <Col md={6}>
                <SalesAnalytics2
                  contato={grafico5?.contato || 0}
                  negociando={grafico5?.negociando || 0}
                  semSucesso={grafico5?.semSucesso || 0}
                  fechados={grafico5?.fechados || 0}
                  dataColors='["--bs-primary", "--bs-warning", "--bs-danger", "--bs-success"]'
                  dashboard={true}
                />
              </Col>
  
              <Col md={6}>
                <RankingComponent data={grafico6 || {}} />
              </Col>
  
              <Col md={6}>
                <RankingFTDsAtendimento
                  week={metrics?.resultWeek?.atendimento?.totalsAtendimento || 0}
                  month={metrics?.resultMonth?.atendimento?.totalsAtendimento || 0}
                  year={metrics?.resultYear?.atendimento?.totalsAtendimento || 0}
                  monthLast={metrics?.resultLastMonth?.atendimento?.totalsAtendimento || 0}
                />
              </Col>
              <Col md={6}>
                <RankingFTDs
                  week={metrics?.resultWeek?.totals || 0}
                  month={metrics?.resultMonth?.totals || 0}
                  year={metrics?.resultYear?.totals || 0}
                  monthLast={metrics?.resultLastMonth?.totals || 0}
                />
              </Col>
            </Row>
            <CadastrarInfluencerManual />
          </Container>
        </div>
      ) : (
        <Loading />
      )}
    </React.Fragment>
  );
  
  
};

export default DashboardSaas;
