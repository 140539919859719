import Spinners from "components/Common/Spinner"
import api from "config/api"
import { AuthContext } from "contexts/auth"
import React, { useState, useEffect, useContext } from "react"
import { Link } from "react-router-dom"
import { Card, CardBody, Col, Row, Badge } from "reactstrap"

const List = ({ listData }) => {
  const { user } = useContext(AuthContext);
  const [influencers, setInfluencers] = useState([]); // Definindo o estado influencers
  const [isLoading, setLoading] = useState(true);
  
  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: 'HEAD' });
  
      if (response.status === 200) {
        return photoUrl;
      } else {
        // console.error(`Erro ao verificar imagem - Status ${response.status} para a URL: ${photoUrl}`);
        return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
      }
    } catch (error) {
      // console.error('Erro ao verificar imagem:', error, 'para a URL:', photoUrl);
      return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
    }
  }
  
  useEffect(() => {
async function updateFoto(){
  const influencersWithPhotos = await Promise.all(listData.map(async influencer => {
    const photoUrl = await verificarImagem(influencer.influencer_rent.photo);
    return { ...influencer, influencer_rent: { ...influencer.influencer_rent, photo: photoUrl } };
  }));
  setInfluencers(influencersWithPhotos);
}
updateFoto();
  }, [listData]);
  
  

  const activeBtn = ele => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active")
    } else {
      ele.closest("button").classList.add("active")
    }
  }

  return (
    <React.Fragment>
      <Row>
        {influencers.map((influencer, key) =>
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3" >
                <div className="flex-grow-1">
                   {/*  <span
                      className={
                        influencer &&
                        influencer?.type_user
                          ? sectorBadgeClasses(
                              influencer?.type_user.type
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.responsavel_rent?.fullname
                        : "Nome indisponível"}
                    </span> */}

                <span style={{marginRight: '5px'}}
                      className={
                        influencer &&
                        influencer?.posts
                          ? sectorBadgeClassesPosts(
                              influencer?.posts
                            )
                          : "badge badge-soft-warning"
                      }
                    >
                      {influencer
                        ? influencer.influencer_rent?.captacao_responsavel?.fullname
                        : "Nome indisponível"}
                    </span>
                    <span style={{marginRight: '5px'}}
                      className={
                        influencer &&
                        influencer?.posts
                          ? sectorBadgeClassesPosts(
                              influencer?.posts
                            )
                          : "badge badge-soft-info"
                      }
                    >
                      {influencer
                        ? influencer.influencer_rent?.captacao_influencer?.fullname
                        : "Nome indisponível"}
                    </span>
                    <span style={{marginRight: '5px'}}
                      className={
                        influencer &&
                        influencer?.posts
                          ? sectorBadgeClassesPosts(
                              influencer?.posts
                            )
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer
                        ? influencer.responsavel_rent?.fullname
                        : "Nome indisponível"}
                    </span>

                  </div>

                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={e => activeBtn(e.target)}
                  >
                    <i className="bx bx-heart"></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer?.influencer_rent.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer?.influencer_rent.fullname}
                  </h6>
                  <p className="font-size-12 mb-0 text-muted">
                    @{influencer?.influencer_rent.username}
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/influencer/${influencer.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    Ver perfil
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        )}
      </Row>
      {isLoading && <Spinners setLoading={setLoading} />}
    </React.Fragment>
  )
}

export default List
