import api from "config/api"
import React, { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { AuthContext } from "contexts/auth"
import {
  Card,
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import Toastr from "../../../components/Ui/Notifications"

const List = ({ listData }) => {
  
  const { user } = useContext(AuthContext)
  const [showModal, setShowModal] = useState(false)
  const [influencers, setInfluencers] = useState([])
  const [idInfluencer, setIdInfluencer] = useState(null)
  const [requestArchiving, setRequestArchiving] = useState([])

  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: "HEAD" })

      if (response.status === 200) {
        return photoUrl
      } else {
        // console.error(`Erro ao verificar imagem - Status ${response.status} para a URL: ${photoUrl}`);
        return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg"
      }
    } catch (error) {
      // console.error('Erro ao verificar imagem:', error, 'para a URL:', photoUrl);
      return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg"
    }
  }

  useEffect(() => {
    async function updateFoto() {
      const influencersWithPhotos = await Promise.all(
        listData.map(async influencer => {
          const photoUrl = await verificarImagem(
            influencer.influencer_rent.photo
          )
          return {
            ...influencer,
            influencer_rent: { ...influencer.influencer_rent, photo: photoUrl },
          }
        })
      )
      setInfluencers(influencersWithPhotos)
    }
    updateFoto()
  }, [listData])

  const activeBtn = (id, archiving) => {
    if (archiving || requestArchiving.includes(id)) {
      Toastr("warning", "Você já enviou uma solicitação.")
    } else {
      setIdInfluencer(id)
      setShowModal(true)
    }
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleArchiveConfirm = async () => {
    try {
      await api.put(
        "/influencers/update/archiving/request",
        {
          id: idInfluencer,
          request_archiving: true,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      )
      Toastr(
        "success",
        "Solicitação de arquivamento enviada aos administradores!"
      )
      setRequestArchiving([...requestArchiving, idInfluencer])
      setShowModal(false)
    } catch (err) {
      console.error("Erro" + err)
    }
  }

  const sectorBadgeClassesPosts = sectorType => {
    let option = "Não Posta Hoje"
    
    if (Array.isArray(sectorType) && sectorType.length > 0) {
      sectorType.forEach(element => {
        const elementDate = new Date(element.start)
        const today = new Date()

        if (elementDate.toDateString() === today.toDateString()) {
          if (element.waiting === true) {
            option = "Aguardando"
          } else {
            option = element.posted === true ? "Postou" : "Não Postou"
          }
        }
      })
    }

    switch (option) {
      case "Postou":
        return "badge badge-soft-success"
      case "Aguardando":
        return "badge badge-soft-warning"
      case "Não Postou":
        return "badge badge-soft-danger"
      case "Não Posta Hoje":
        return "badge badge-soft-info"
      default:
        return "badge badge-soft-warning"
    }
  }


  const sectorBadgeSuper = sectorType => {
    let hasSuperBet = false;
    let hasLuvaBet = false;
  
    if (typeof sectorType === 'string') {
      hasSuperBet = sectorType.includes("SuperBet");
      hasLuvaBet = sectorType.includes("LuvaBet");
    } else {
      return "badge badge-soft-info";
    }
    if (hasSuperBet && hasLuvaBet) {
      return "badge badge-soft-warning"; 
    }
    if (hasSuperBet) {
      return "badge badge-soft-danger"; 
    }
    if (hasLuvaBet) {
      return "badge badge-soft-success"; 
    }
      return "badge badge-soft-warning";
  };

  
  return (
    <React.Fragment>
      <Row>
        {(influencers || []).map((influencer, key) =>
          influencer.influencer_rent.archiving ? null : (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span
                        className={
                          influencer.influencer_rent &&
                          influencer.influencer_rent?.posts
                            ? sectorBadgeClassesPosts(
                                influencer.influencer_rent?.posts
                              )
                            : "badge badge-soft-success"
                        }
                      >
                        {influencer
                          ? influencer.responsavel_rent?.fullname
                          : "Nome indisponível"}
                      </span>
                      <span
                        className={
                          influencer.influencer_rent &&
                          influencer.influencer_rent?.campaigns_influencer?.name
                            ? sectorBadgeSuper(
                                influencer.influencer_rent?.campaigns_influencer
                                  ?.name
                              )
                            : "badge badge-soft-success"
                        }
                        style={{ marginLeft: "10px" }} 
                      >
                        {influencer
                          ? influencer.influencer_rent?.campaigns_influencer
                              ?.name
                          : "Nome indisponível"}
                      </span>

                      <span
                        className={
                            "badge badge-soft-info"
                        }
                        style={{ marginLeft: "10px" }} 
                      >
                        {influencer
                          ? influencer.influencer_rent?.influencer_meta[0]?.isDailyPayment ? "Diário" : null
                          : "Nome indisponível"}
                      </span>

                      <span
                        className={
                          "badge badge-soft-info"
                        }
                        style={{ marginLeft: "10px" }} 
                      >
                        {influencer
                          ? influencer.influencer_rent?.influencer_meta[0]?.isAdvanceValue ? "Antecipado" : null
                          : "Nome indisponível"}
                      </span>
                    </div>

                    <button
                      type="button"
                      className="btn btn-light btn-sm like-btn"
                      onClick={() =>
                        activeBtn(
                          influencer.influencer_rent.id,
                          influencer.influencer_rent.request_archiving
                        )
                      }
                    >
                      <i
                        className="mdi mdi-archive-arrow-down-outline"
                        style={{ fontSize: "14px" }}
                      ></i>
                    </button>
                  </div>
                  <div className="text-center mb-3">
                    <img
                      src={influencer.influencer_rent.photo}
                      alt=""
                      className="avatar-sm rounded-circle"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      {influencer.influencer_rent.fullname}
                    </h6>
                    <p className="mb-0 text-muted font-size-12">
                      @{influencer.influencer_rent.username}
                    </p>
                  </div>
                  <div className="mt-4 pt-1">
                    <Link
                      to={`/influencer/${influencer.id}`}
                      className="btn btn-soft-primary d-block"
                    >
                      <span className="text-muted">Ver postagens</span>
                    </Link>
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        )}
      </Row>

      {/* Modal do influenciador */}
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Arquivar Influenciador
        </ModalHeader>
        <ModalBody>
          Você deseja solicitar o arquivamento deste influenciador?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
      {/* Final do modal */}
    </React.Fragment>
  )
}

export default List
