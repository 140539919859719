import React, { useState, useContext, useEffect } from "react";
import { Card, CardBody, Col, Spinner } from "reactstrap";

import "./overview.css";
import {
  CardBodyColaborador,
  ImagePlugChat,
  CardBodyColaboradorInfo,
  ImageColaborador,
  Buttons,
  SidebarHeader,
  SidebarHeaderText,
  SidebarHeaderH3,
  ButtonsPayments,
} from "./styles";

import Toastr from "../../../components/Ui/Notifications";

import PlugChatImg from "../../../assets/images/plugchat.png";
import ImgProfile from "../../../assets/images/imgProfile.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch, faCopy } from "@fortawesome/free-solid-svg-icons";
import AddPaymentModal from "../../../components/Modals/AddPaymentModal"; 
import PaymentDetailsModal from "../../../components/Modals/PaymentDetailsModal"; 
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import EditValuePayment from "../../../components/Modals/EditValuePayment";
// Importe images
import adobephotoshop from "../../../assets/images/companies/adobe-photoshop.svg";
import { StateContext } from "contexts/states";
import PlugchatModal from "components/Modals/PlugChat";
import api from "config/api";
import EditObservation from "../../../components/Modals/EditObservation";
import { AuthContext } from "contexts/auth";
import Loading from "../../../components/Ui/LoadingCircle/index"

const Overview = ({ informations, photo }) => {
  const [copiado, setCopiado] = useState(false);
  const [copiadoApp, setCopiadoApp] = useState(false);
  const [roleta, setRoleta] = useState(false);
  const { user } = useContext(AuthContext)

  const { addValue, setValue, addValueSemanal, setAddValueSemanal, detailsValue, setDetailsValue } = useContext(StateContext)
  const [information, setInformations] = useState("")
  const [showEditObservation, setShowEditObservation] = useState(false);
  const [observation, setObservation] = useState();
  const [meta, setMeta] = useState(null);
  const [loading, setLoading] = useState(true);
  const [betano, setBetano] = useState("");
  const [superBet, setSuperBet] = useState("");
  const [email, setEmail] = useState(null);


  const copiarTextoSuperbet = () => {
    const texto = `https://brsuperbet.link/${informations.influencer_rent?.username}`
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        setSuperBet(true)
        setTimeout(() => setCopiado(false), 2000)
      })
      .catch(err => {
        console.error("Erro ao copiar texto: ", err)
      })
  }
  const copiarTextoBetano= () => {
    const texto = `https://brbetano.link/${informations.influencer_rent?.username}`
    navigator.clipboard
      .writeText(texto)
      .then(() => {
        setBetano(true)
        setTimeout(() => setCopiado(false), 2000)
      })
      .catch(err => {
        console.error("Erro ao copiar texto: ", err)
      })
  }
  const handleEditObservationClick = () => {
    setShowEditObservation(true);
  };

  useEffect(() => {
    setInformations(informations.influencer_rent?.pix_bank.value)
    setObservation(informations.influencer_rent?.observation)
  }, [informations])

  const onChangeValueObservation = (newValue) => {
    setObservation(newValue)
  }

  function redirect(link) {
    window.open(link, "_blank");
  }
  const handleAddPaymentClick = () => {
    setValue(true);
  };

  const handlePaymentDetailsClick = () => {
    setDetailsValue(true);
  };

  const handleEditValueClick = () => {
    setAddValueSemanal(true);
  };

  const [isPlugChatModalOpen, setIsPlugChatModalOpen] = useState(false);

  const togglePlugChatModal = () => {
    setIsPlugChatModalOpen(!isPlugChatModalOpen);
  };

  const redirectOrToggleModal = () => {

    if (informations.link && informations.link !== "#") {
      window.open(informations.link, '_blank');
    } else {
      togglePlugChatModal();
    }
  };

  const onChangeValue = (newValue) => {
    setInformations(newValue)
  }

  const toggleObservation = () => {
    setShowEditObservation(!showEditObservation);
  }

  const formatar = Intl.NumberFormat('en', {
    notation: 'compact'
  })

  useEffect(() => {
    async function getMeta() {
      try {
        const response = await api.get(`/profile/influencer/meta/${informations.influencer_rent?.id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setMeta(response.data);
        setLoading(false)
      } catch (error) {
        console.error("Erro ao buscar campanha:", error);
        setLoading(false)
      }
    }
  
    async function getEmail() {
      try {
        const response = await api.get(`/profile/influencer/email/${informations.influencer_rent?.id}`, {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });
        setEmail(response.data);
        setLoading(false)
      } catch (error) {
        console.error("Erro ao buscar campanha:", error);
        setLoading(false)
      }
    }
  
    if (informations.influencer_rent?.id) {
      getMeta();
      getEmail();
    }
  }, [user.token, informations.influencer_rent?.id]);
  
  function formatarData(dateString) {
    let date = new Date(dateString);
      
    let day = date.getUTCDate();
    let month = date.getUTCMonth() + 1;
    let year = date.getUTCFullYear();
  
    day = day < 10 ? '0' + day : day;
    month = month < 10 ? '0' + month : month;
  
    return `${day}/${month}/${year}`;
  }

  return (
    <React.Fragment>
      <Col>
        <Card>
          <CardBody>
            <SidebarHeader>
              <div>

                <div>
                  {showEditObservation && <EditObservation id={informations.influencer_rent?.id} observation={observation} controlModal={showEditObservation} toggle={toggleObservation} onChangeValueObservation={onChangeValueObservation} />}
                </div>

                <img
                  style={{
                    width: "100px",
                    height: "100px",
                    borderRadius: "50%",
                  }}
                  className="photo-sidebar"
                  src={photo}
                  alt="foto de perfil influencer"
                />
              </div>

              <SidebarHeaderText>
                <SidebarHeaderH3>
                  {informations.influencer_rent?.fullname}

                </SidebarHeaderH3>
                <h5>@{informations.influencer_rent?.username}</h5>
              </SidebarHeaderText>
              <div className="sidebar-header-seguidores">
                <h6>Seguidores: {formatar.format(informations.influencer_rent?.followers)}</h6>
                <h6>Seguindo: {formatar.format(informations.influencer_rent?.following)}</h6>
              </div>

              <div className="hstack gap-2">
                <Buttons
                  onClick={() => redirect(informations.linkPlug)}
                  className="btn btn-soft-success w-100"
                >
                  <i className="bx bxl-whatsapp"></i>
                </Buttons>

                {/* BOTÃO PLUGCHAT */}


                <Buttons
                  onClick={redirectOrToggleModal}
                  className="btn btn-soft-primary w-100"
                >
                  <i className="bx bx-chat"></i>
                </Buttons>



                <Buttons
                  onClick={() =>
                    redirect(
                      `https://instagram.com/${informations.influencer_rent?.username}`
                    )
                  }
                  className="btn btn-soft-danger w-100"
                >
                  <i className="bx bxl-instagram"></i>
                </Buttons>
              </div>

              <PlugchatModal
                isOpen={isPlugChatModalOpen}
                toggle={togglePlugChatModal}
                id={informations.id}
              />

            </SidebarHeader>
          </CardBody>
        </Card>

        <Card>
          <CardBody>
            <h5 style={{ marginTop: "10px" }} className="fw-semibold">
              Links para divulgação
            </h5>
            {informations.influencer_rent?.campaigns ===
              "a7137b8e-0531-4a15-8543-15d47fb6cf27" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da Superbet</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoSuperbet}
                      >
                        { superBet? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {informations.influencer_rent?.campaigns ===
              "df43c42f8-fe44-4361-882c-0a00efd8d3c2" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da Betano</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoBetano}
                      >
                        {betano ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
            {informations.influencer_rent?.campaigns ===
              "740a566f-a540-4519-b3b6-75d1ae042e47" && (
              <div className="max-width-container table-responsive">
                <table className="table">
                  <tbody>
                    <tr>
                      <th scope="col">Link da SuperBet</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoSuperbet}
                      >
                        {superBet ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                    <tr>
                      <th scope="col">Link da Betano</th>
                      <button
                        style={{
                          border: "none",
                        }}
                        onClick={copiarTextoBetano}
                      >
                        {betano ? (
                          "Copiado!"
                        ) : (
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ fontSize: "20px" }}
                          />
                        )}
                      </button>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}
          </CardBody>
        </Card>


        <Card>
          <CardBody>
            <h5 style={{ marginTop: "10px" }} className="fw-semibold">
              Dados Bancários
            </h5>

            <div className="max-width-container table-responsive">
              <table className="table">
                <tbody>
                  <tr>
                    <th scope="col">Nome</th>
                    <td scope="col">
                      {informations.influencer_rent?.pix_bank.name}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">CPF</th>
                    <td>{informations.influencer_rent?.pix_bank.cpf}</td>
                  </tr>
                  <tr>
                    <th scope="row">Tipo de chave</th>
                    <td>
                      {informations.influencer_rent?.pix_bank.type}
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Pix</th>
                    <td>{informations.influencer_rent?.pix_bank.pix}</td>
                  </tr>

                  <h5 style={{ marginTop: "20px", marginBottom: "20px" }} className="fw-semibold">
                    Financeiro
                  </h5>

                  {
                    loading ? (
                      <div style={{ display: 'flex', justifyContent: 'center'}}>
                        <Spinner />
                      </div>
                    ) : (
                      <>
                        {informations.influencer_rent?.isPaymentFixed === true ? (
                          <tr>
                            <th scope="row">Valor Semanal</th>
                            {addValueSemanal && (
                              <EditValuePayment
                                id={informations.influencer_rent.pix_bank.id}
                                onChangeValue={onChangeValue}
                              />
                            )}
                            <td>
                              <span className="badge badge-soft-info">
                                {information || ""}
                              </span>
                              <button
                                onClick={() => handleEditValueClick()}
                                className="btn btn-link btn-sm"
                              >
                                <FontAwesomeIcon icon={faPencilAlt} />
                              </button>
                            </td>
                          </tr>
                        ) : (
                          <>
                            <tr>
                              <th scope="row">Meta Semanal</th>
                              <td>{meta?.meta || "R$ 0,00"}</td>
                            </tr>
                            <tr>
                              <th className="" scope="row">Valor FTD</th>
                              <td>{meta?.valueMin || "R$ 0,00"}</td>
                            </tr>
                            <tr>
                              <th scope="row">Valor Excedente (Bônus)</th>
                              <td>{meta?.valueBonus || "R$ 0,00"}</td>
                            </tr>
                            <tr>
                              <th scope="row">Inicio da Meta</th>
                              <td>{formatarData(meta?.dateInit) || null}</td>
                            </tr>
                            <tr>
                              <th scope="row">Final da meta</th>
                              <td>{formatarData(meta?.dateFinal) || null}</td>
                            </tr>
                            <tr>
                            <th scope="row">Pagamento Diário</th>
                            <td>{meta?.isDailyPayment === true ? 'Sim': 'Não'}</td>
                          </tr>
                            <tr>
                              <th scope="row">Pagamento antecipado</th>
                              <td>{meta?.isAdvanceValue === true ? "Sim": "Não" || null}</td>
                            </tr>
                        
                         {
                        meta?.isAdvanceValue === true &&  
                          <tr>
                            <th scope="row">Valor antecipado</th>
                            <td>{meta?.advanceValue || "R$ 0,00"}</td>
                          </tr>
                         }      
                         
                                         
                          </>
                        )}
                      </>
                    )
                  }

                  <tr>
                    <h6
                      style={{
                        textAlign: "left",
                        justifyContent: "center",
                        marginTop: "10px",
                      }}
                      className="fw-semibold"
                    >
                      Pagamentos
                    </h6>
                    <td>
                      <div className="d-flex align-items-center">
                        <ButtonsPayments
                          onClick={() => handleAddPaymentClick()}
                          className="btn btn-soft-success w-50 me-2"
                        >
                          <FontAwesomeIcon
                            icon={faPlus}
                            style={{ fontSize: "16px" }}
                          />
                        </ButtonsPayments>
                        <ButtonsPayments
                          onClick={() => handlePaymentDetailsClick()}
                          className="btn btn-soft-info w-50 me-2"
                        >
                          <FontAwesomeIcon
                            icon={faSearch}
                            style={{ fontSize: "16px" }}
                          />
                        </ButtonsPayments>
                        {addValue && <AddPaymentModal idInfluencer={informations.influencer_rent?.id} />}
                        {detailsValue && <PaymentDetailsModal influencerId={informations.influencer_rent.id} />}
                      </div>
                    </td>

                  </tr>
                </tbody>
                <h5 style={{ marginTop: "20px", marginBottom: "20px" }} className="fw-semibold">
                  Mais Informações
                </h5>
                <tr>
                  <th scope="row">Observações</th>
                  <td>
                    <div className="observation-wrapper">
                      <span className="badge badge-soft-info observation-text">
                        {observation}
                      </span>
                      <button
                        onClick={() => handleEditObservationClick()}
                        className="btn btn-link btn-sm edit-icon"
                      >
                        <FontAwesomeIcon icon={faPencilAlt} />
                      </button>
                    </div>
                  </td>
                </tr>

              </table>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardBody>
            <div className="text-center">
              <img
                src={adobephotoshop}
                alt=""
                height="50"
                className="mx-auto d-block"
              />
              <h5 className="mt-3 mb-1">Contatos</h5>
              <p className="text-muted mb-0">Mais</p>
            </div>

            <ul className="list-unstyled mt-4">

              <li>
                <div className="d-flex">
                  <i className="bx bxl-whatsapp text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Telefone</h6>
                    <p className="text-muted fs-14 mb-0">
                      {informations.influencer_rent?.phone}
                    </p>
                  </div>
                </div>
              </li>

              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-money text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Plataforma dos influencers</h6>
                    <p className="text-muted fs-10 mb-0">
                      <a href="https://virawinfluencers.com.br">Plataforma dos influenciadores</a>
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-3">
                <div className="d-flex">
                  <i className="bx bx-mail-send text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Email</h6>
                    <p className="text-muted fs-10 mb-0">
                    {email?.email}
                    </p>
                  </div>
                </div>
              </li>

              <li className="mt-3">
                <div className="d-flex">
                <i className="bx bxs-lock-alt text-primary fs-4"></i>
                  <div className="ms-3">
                    <h6 className="fs-14 mb-2">Senha de login</h6>
                    <p className="text-muted fs-10 mb-0">
                    <h5>viraw{informations.influencer_rent?.username}#123</h5>
                    </p>
                  </div>
                </div>
              </li>
            </ul>
            <div className="mt-4"></div>
          </CardBody>
        </Card>
      </Col>

    </React.Fragment>
  );
};

export default Overview;