import React, { useState } from "react";
import { Row, Col, Card, CardBody, CardTitle, CardSubtitle, Table, Button } from "reactstrap";

const RankingComponent = (props) => {
  const [filtroRanking, setFiltroRanking] = useState('hoje');

  const ordenarPorAdicionadosHoje = (dados) => {
    return dados.sort((a, b) => b.adicionadosHoje - a.adicionadosHoje);
  };

  const ordenarPorAdicionadosSemana = (dados) => {
    return dados.sort((a, b) => b.adicionadosSemanal - a.adicionadosSemanal);
  };

  const ordenarPorAdicionadosMes = (dados) => {
    return dados.sort((a, b) => b.adicionadosMensal - a.adicionadosMensal);
  };

  const filtrarDados = (filtro) => {
    setFiltroRanking(filtro);
  };

  const dadosOrdenados = () => {
    switch (filtroRanking) {
      case 'hoje':
        return ordenarPorAdicionadosHoje([...props.data]);
      case 'semana':
        return ordenarPorAdicionadosSemana([...props.data]);
      case 'mes':
        return ordenarPorAdicionadosMes([...props.data]);
      default:
        return ordenarPorAdicionadosHoje([...props.data]);
    }
  };

  return (
    <React.Fragment>
      <Row>
        <Col>
          <Card>
            <CardBody>
              <CardTitle className="h4">Ranking de Hunters GERAL</CardTitle>
              <CardSubtitle className="card-title-desc">
                O Ranking é atualizado de acordo com o número de influenciadores adicionados por cada colaborador do setor de <code>HUNTER</code>. Esse é um ranking geral atualizado.
              </CardSubtitle>
              <div className="mb-3">
                <Button color="primary" onClick={() => filtrarDados('hoje')} className={filtroRanking === 'hoje' ? 'active' : ''}>Hoje</Button>{' '}
                <Button color="primary" onClick={() => filtrarDados('semana')} className={filtroRanking === 'semana' ? 'active' : ''}>Semana</Button>{' '}
                <Button color="primary" onClick={() => filtrarDados('mes')} className={filtroRanking === 'mes' ? 'active' : ''}>Mês</Button>
              </div>
              <div className="table-responsive">
                <Table className="table table-sm m-0">
                  <thead>
                    <tr>
                      <th>Nº</th>
                      <th>Colaborador</th>
                      <th style={{ textAlign: "center" }}>Adicionados</th>
                      <th style={{ textAlign: "center" }}>Fechados</th>
                      <th style={{ textAlign: "center" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {dadosOrdenados().map((user, index) => (
                      <tr key={user.id}>
                        <th scope="row" style={{ textAlign: "center" }}>
                          {index + 1}
                        </th>
                        <td>{user.name}</td>
                        <td style={{ textAlign: "center" }}>{filtroRanking === 'hoje' ? user.adicionadosHoje : (filtroRanking === 'semana' ? user.adicionadosSemanal : user.adicionadosMensal)}</td>
                        <td style={{ textAlign: "center" }}>{filtroRanking === 'hoje' ? user.fechadosHoje : (filtroRanking === 'semana' ? user.fechadosSemana : user.fechadosMes)}</td>
                        <td style={{ textAlign: "center" }}>{user.adicionadosTotal}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default RankingComponent;
