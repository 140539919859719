import React from "react"
import PropTypes from "prop-types"
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap"
import { Link } from "react-router-dom"

const Profile = ({ data, geral, listData }) => {


  const influencer = data;


  return (
    <Card>
      <CardBody >
        <div className="d-flex">
          <div className="me-4">
            <img src="https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg" alt="Foto do usuário" className="img-fluid rounded-circle" width="40" height="40" />
          </div>


          <div className="flex-grow-1">

            <div className="text-muted">
              <h5>{influencer.fullname}</h5>
              <h6 className="mb-1 text-muted">@{influencer.username}</h6>
            </div>

          </div>
            <div className="text-muted"> 
              <h4 className="mb-1 text-muted">{influencer.company}</h4>
            </div>


        </div>
      </CardBody>
      <CardBody className="border-top">
        <Row>
          <Col sm="5">
            <div className="text-center">
              <p className="text-muted mb-2">Registros</p>
              <h5>{geral.Registrations}</h5>
            </div>
          </Col>
          <Col sm="3">
            <div className="text-center">
              <p className="text-muted mb-2">FTD</p>
              <h5>{geral.FirstDepositCount}</h5>
            </div>
          </Col>
          <Col sm="3">
            <div className="text-center">
              <p className="text-muted mb-2">CPA</p>
              <h5>{geral.CPACount}</h5>
            </div>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md="6" sm="5">
            <div className="text-center">
              <p className="text-muted mb-2">Visitas</p>
              <h5>{geral.Clicks}</h5>
            </div>
          </Col>
       
        </Row>
      </CardBody>
    </Card>
  )
}

Profile.propTypes = {
  data: PropTypes.any,
  isMenu: PropTypes.bool,
  toggleMenu: PropTypes.func,
}

export default Profile
