import React, { useContext } from "react"
import { AuthContext } from "../contexts/auth"
import AppCaptacao from "./app.captacao.routes"
import AppGrupos from "./app.grupos.routes"
import AppAdmin from "./app.admin.routes"
import AppDiretoria from "./app.diretoria.routes"
import AppExterno from "./app.externo.routes";
import AppHunter from "./app.hunter.routes";
import Auth from "./auth.routes"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import VerticalLayout from "../components/VerticalLayout/"
import { layoutTypes } from "../constants/layout"
import Loading from "components/Ui/Loading"

const getLayout = layoutType => {
  let Layout = VerticalLayout
  switch (layoutType) {
    case layoutTypes.VERTICAL:
      Layout = VerticalLayout
      break
    default:
      break
  }
  return Layout
}

export default function Index() {
  const { user, loading } = useContext(AuthContext)
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = getLayout(layoutType)

  if (loading) {
    return <Loading />
  }

  if (user) {
    if (user.type === 2) {
      return (
        <Layout>
          <AppCaptacao />
        </Layout>
      )
    } else if (user.type === 3) {
      return (
        <Layout>
          <AppGrupos />
        </Layout>
      )
    }
    else if (user.type === 1) {
      return (
        <Layout>
          <AppAdmin />
        </Layout>
      )
    }
    else if (user.type === 6) {
      return (
        <Layout>
          <AppDiretoria />
        </Layout>
      )
    }
    else if (user.type === 4) {
      return (
        <Layout>
          <AppExterno />
        </Layout>
      )
    }
    else if (user.type === 5) {
      return (
        <Layout>
          <AppHunter />
        </Layout>
      )
    }
  } else {
    return <Auth />
  }
}
