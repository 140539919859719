import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { AuthContext } from "contexts/auth";
import api from "config/api";
import Toastr from "../../../components/Ui/Notifications";

const List = ({ listData }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const [idInfluencer, setIdInfluencer] = useState(null);
  const [requestArchiving, setRequestArchiving] = useState([]);
  const [selectedCampaign, setSelectedCampaign] = useState("");
  const [campaignOptions, setCampaignOptions] = useState([]);
  const [idCampanha, setIdCampanha] = useState('');

  const activeBtn = (id, archiving) => {
    if (archiving || requestArchiving.includes(id)) {
      Toastr("warning", "Você já enviou uma solicitação.");
    } else {
      setIdInfluencer(id);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCampaign("");
  };

  const handleArchiveConfirm = async () => {
    if (!selectedCampaign) {
      Toastr("warning", "Por favor, escolha uma campanha antes de continuar.");
      return;
    }

    try {
      await api.put(
        "/influencers/update/reactive/request",
        {
          id: idInfluencer,
          request_archiving: true,
          new_campaign: selectedCampaign,
        },
        {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        }
      );
      Toastr("success", "Solicitação de desarquivamento enviada aos administradores!");
      setRequestArchiving([...requestArchiving, idInfluencer]);
      setShowModal(false);
    } catch (err) {
      console.error("Erro: " + err);
    }
  };

  const handleCampaignChange = (e) => {
    setSelectedCampaign(e.target.value);
  };

  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: "HEAD" });

      if (response.status === 200) {
        return photoUrl;
      } else {
        return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
      }
    } catch (error) {
      return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
    }
  }

  useEffect(() => {
    async function updateFoto() {
      const archivedInfluencers = listData.filter((influencer) => {
        return (
          influencer.influencer_rent.archiving &&
          influencer.responsavel_rent.id === user.userId
        );
      });

      const influencersWithPhotos = await Promise.all(
        archivedInfluencers.map(async (influencer) => {
          const photoUrl = await verificarImagem(influencer.influencer_rent.photo);
          return {
            ...influencer,
            influencer_rent: { ...influencer.influencer_rent, photo: photoUrl },
          };
        })
      );
      setInfluencers(influencersWithPhotos);
    }

    updateFoto();
  }, [listData, user]);

  useEffect(() => {
    async function getCampanha() {
      try {
        const response = await api.get("/campaigns", {
          headers: {
            Authorization: `Bearer ${user.token}`,
          },
        });

        const filteredCampaigns = response.data.filter(
          (campaign) => campaign.name !== "Estrela Bet" && campaign.name !== "Aposta Ganha"
        );

        setCampaignOptions(filteredCampaigns);
      } catch (error) {
        console.error(error);
      }
    }
    getCampanha();
  }, [user.token]);

  return (
    <React.Fragment>
      <Row>
        {influencers.map((influencer, key) => (
          <Col xl={3} key={key}>
            <Card>
              <CardBody>
                <div className="d-flex align-start mb-3">
                  <div className="flex-grow-1">
                    <span
                      className={
                        influencer.responsavel_rent &&
                        influencer.responsavel_rent.type_user
                          ? sectorBadgeClasses(influencer.responsavel_rent.type_user.type)
                          : "badge badge-soft-success"
                      }
                    >
                      {influencer.responsavel_rent
                        ? influencer.responsavel_rent.fullname
                        : "Nome indisponível"}
                    </span>
                  </div>
                  <button
                    type="button"
                    className="btn btn-light btn-sm like-btn"
                    onClick={() =>
                      activeBtn(influencer.influencer_rent.id, influencer.influencer_rent.request_archiving)
                    }
                  >
                    <i
                      className="mdi mdi-account-reactivate-outline"
                      style={{ fontSize: "14px" }}
                    ></i>
                  </button>
                </div>
                <div className="text-center mb-3">
                  <img
                    src={influencer.influencer_rent.photo}
                    alt=""
                    className="avatar-sm rounded-circle"
                  />
                  <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                    {influencer.influencer_rent.fullname}
                  </h6>
                  <p className="mb-0 text-muted font-size-12">
                    @{influencer.influencer_rent.username}
                  </p>
                </div>
                <div className="mt-4 pt-1">
                  <Link
                    to={`/influencer/${influencer.id}`}
                    className="btn btn-soft-primary d-block"
                  >
                    <span className="text-muted">Ver perfil</span>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </Col>
        ))}
      </Row>

      {/* Modal do influenciador */}
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Desarquivar Influenciador
        </ModalHeader>
        <ModalBody>
          Você deseja solicitar o desarquivamento deste influenciador?
        </ModalBody>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "10px",
          }}
        >
          <label
            style={{
              justifyContent: "center",
              width: "100%",
              fontSize: "16px",
              marginBottom: "10px",
              textAlign: "center",
            }}
          >
            Escolha a campanha para o influenciador
          </label>
          <select
            style={{
              width: "100%",
              height: "35px",
              borderRadius: "4px",
              padding: "5px",
              textAlign: "center",
            }}
            name="select"
            value={selectedCampaign} 
            onChange={handleCampaignChange}
          >
            <option value="" disabled>
              Escolha uma campanha
            </option>
            {campaignOptions.map((campaign) => (
              <option key={campaign.id} value={campaign.id}>
                {campaign.name}
              </option>
            ))}
          </select>
        </div>

        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
      {/* Final do modal */}
    </React.Fragment>
  );
};

export default List;
