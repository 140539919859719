import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AuthContext } from "contexts/auth";
import {
  Card,
  CardBody,
  Col,
  Row,
} from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";
import ButtonInstagram from "./ButtonInstagram/index"
import UpdateCaptador from "../../../components/Modals/UpdateCaptador"

const List = ({ listData, statusFilter }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [influencers, setInfluencers] = useState([]);
  const [idInfluencer, setIdInfluencer] = useState(null);
  const [updatedCaptador, setUpdatedCaptador] = useState(null);

  useEffect(() => {
    async function updateFoto() {
      const influencersWithPhotos = await Promise.all(listData.map(async influencer => {
        const photoUrl = await verificarImagem(influencer?.photo);
        return {
          ...influencer,
          photo: photoUrl
        };
      }));
      
      setInfluencers(influencersWithPhotos);
    }
    updateFoto() 
  }, [])

  async function verificarImagem(photoUrl) {
    try {
      const response = await fetch(photoUrl, { method: 'HEAD' });

      if (response.status === 200) {
        return photoUrl;
      } else {
        return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
      }
    } catch (error) {
      return "https://i.ibb.co/FJprc95/341925638-221404147152223-6159496734469420913-n.jpg";
    }
  }
  const handleOpenModal = (influencerId) => {
    setIdInfluencer(influencerId);
    setShowModal(true);
  };

  const handleChangeCaptador = (newCaptador) => {
    setUpdatedCaptador(newCaptador);
  };

  const getStatusBadgeClass = (status) => {
    switch (status) {
      case 1:
        return "badge badge-soft-info";
      case 2:
        return "badge badge-soft-primary";
      case 3:
        return "badge badge-soft-danger";
      case 4:
        return "badge badge-soft-success";
      case 5:
        return "badge badge-soft-warning";
      default:
        return "badge badge-soft-info";
    }
  };

  return (
    <React.Fragment>
      <Row>
        {(listData || []).map((influencer, key) => (
          (!statusFilter || influencer.status.toString() === statusFilter) && (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div className="flex-grow-1">
                      <span
                        className={getStatusBadgeClass(influencer.status)}
                      >
                        {influencer ? (
                          <>
                            {influencer?.captacao_influencer?.fullname}{" "}
                            {influencer.status === 5 && (
                              <i
                                className="mdi mdi-pencil ml-1"
                                style={{ cursor: "pointer", color: "#FFA500" }}
                                onClick={() =>
                                  handleOpenModal(influencer.id)
                                }
                              ></i>
                            )}
                          </>
                        ) : (
                          "Nome indisponível"
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="text-center mb-3">
                    <img
                      src={influencer.photo}
                      alt=""
                      className="avatar-sm rounded-circle"
                    />
                    <h6 className="font-size-15 mt-3 mb-1 text-truncate">
                      {influencer.fullname}
                    </h6>
                    <p className="mb-0 text-muted font-size-12">
                      @{influencer.username}
                    </p>
                  </div>
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <ButtonInstagram
                      followers={influencer?.followers}
                      username={influencer?.username}
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          )
        ))}
      </Row>
      {showModal && (
        <UpdateCaptador
          influencerId={idInfluencer} 
          controlModal={showModal}
          toggle={() => setShowModal(!showModal)}
          onChangeCaptador={handleChangeCaptador}
        />
      )}
    </React.Fragment>
  );
};

export default List;
