import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card } from 'reactstrap';
import { AuthContext } from "contexts/auth";
import { archive_Contributor } from "./functionDesativar";
import api from '../../../config/api';

import {
  CardBody,
  Col,
  Row,
  Badge,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import Toastr from "../../../components/Ui/Notifications";

const List = ({ listData }) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [selectedInfluencer, setSelectedInfluencer] = useState(null);
  const [colaboradores, setColaboradores] = useState([])

  useEffect(() => {
    getCollaborators()
  }, [user.token])

  const updated = () => {
    getCollaborators()
  }

  async function getCollaborators() {
    await api.get("/users/api/3", {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then((response) => {
      setColaboradores(response.data);
    }).catch((error) => {
      console.error(error);
    })
  }

  const handleOpenModal = (influencer) => {
    setSelectedInfluencer(influencer);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleArchiveConfirm = () => {
    api.put("/users/archiving/", {
      id: selectedInfluencer.id,
      active: false,
      sort: false
    }, {
      headers: {
        Authorization: `Bearer ${user.token}`
      }
    }).then(response => {
      Toastr("success", "Colaborador desativado com sucesso!");
      setTimeout(() => {
        window.location.reload();  
      }, 3000);
      
      handleCloseModal();
    }).catch(error => {
      console.error("Erro ao desativar colaborador:", error);
    });
  };
  
  const activeBtn = (ele) => {
    if (ele.closest("button").classList.contains("active")) {
      ele.closest("button").classList.remove("active");
    } else {
      ele.closest("button").classList.add("active");
    }
  };

  const sectorBadgeClasses = (sectorType) => {
    switch (sectorType) {
      case 'Grupos':
        return 'badge badge-soft-success';
      case 'Captação':
        return 'badge badge-soft-info';
      case 'Diretoria':
        return 'badge badge-soft-dark';
      default:
        return 'badge badge-soft-warning';
    }
  };

  return (
    <React.Fragment>
      <Row>

        {listData
          .filter(influencer => influencer.active) 
          .map((influencer, key) => (
            <Col xl={3} key={key}>
              <Card>
                <CardBody>
                  <div className="d-flex align-start mb-3">
                    <div>
                      
                    </div>
                    
                    <div className="flex-grow-1">
                      <span className={sectorBadgeClasses(influencer.type_user.type)}>
                        {influencer.type_user.type === 'Captação' ? 'Closer' : influencer.type_user.type}
                      </span>
                    </div>
                    {influencer.type_user.type === 'Grupos' ? (
                              <div className="flex-grow-1">
                              <span className={sectorBadgeClasses(influencer.type_user.type)}>
                                {influencer.type_user.type === 'Captação' ? '' : influencer.plug_chat.length} ativos
                              </span>
                            </div>
                    ) : null}
            
                    {influencer.type_user.type === 'Captação'?
                    <button 
                    type="button"
                    className={`btn btn-light btn-sm like-btn${(influencer.type_user.type === 'Diretoria' || influencer.type_user.type === 'Hunter') ? ' disabled-btn' : ''}`}
                    onClick={() => handleOpenModal(influencer)}
                  >
                    <i className="mdi mdi-archive-arrow-down-outline" style={{ fontSize: "14px" }}></i>
                  </button> : null
                  }
                  </div>
                  <div className="text-center mb-3">
                    <img src={influencer.photo} alt="" className="avatar-sm rounded-circle" />
                    <h6 className="font-size-15 mt-3 mb-1">{influencer.fullname}</h6>
                    <p className="mb-0 text-muted font-size-12">{influencer.email}</p>
                  </div>
                  <div className="mt-4 pt-1">
                    {(influencer.type_user.type === 'Diretoria' || influencer.type_user.type === 'Hunter') ? (
                      <button className="btn btn-soft-primary d-block " style={{ width: '100%' }} disabled>Ver Perfil</button>
                    ) : (
                      <Link to={`/colaboradores/${influencer.id}`} className="btn btn-soft-primary d-block">Ver Perfil</Link>
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          ))}

      </Row>

      {/* Modal do influenciador */}
      <Modal isOpen={showModal} toggle={handleCloseModal}>
        <ModalHeader toggle={handleCloseModal}>
          Arquivar Colaborador
        </ModalHeader>
        <ModalBody>
          Você deseja desativar este colaborador?
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleCloseModal}>
            Cancelar
          </Button>
          <Button color="primary" onClick={handleArchiveConfirm}>
            Sim
          </Button>
        </ModalFooter>
      </Modal>
      {/* Final do modal */}

    </React.Fragment>
  );
};

export default List;
