import React, { useContext, useEffect, useState } from 'react';
import { Container, Row, Col, Input, FormGroup} from 'reactstrap';
import ChartSection from './ChartSection';
import JobVacancy from './JobVacancy';
import ReceivedTime from './ReceivedTime';
import SalesAnalytics2 from "./sales-analytics";
import EGames from "./egames";
import Section from './Section';
import StatisticsApplications from './StatisticsApplications';
import { AuthContext } from 'contexts/auth';
import api from 'config/api';
import CandidateSection from './CandidateSection';
import Loading from "components/Ui/LoadingCircle"

const DashboardJob = () => {
    const { user } = useContext(AuthContext);
    const [data, setData] = useState({});
    const [meses, setMeses] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedOption, setSelectedOption] = useState('todos');
    const [selectedOptionLabel, setSelectedOptionLabel] = useState('Total');

    function getMonthName(dateString) {
        if (typeof dateString !== 'string' || !/^(\d{4})-(\d{2})$/.test(dateString)) {
            throw new Error('Data inválida. Use o formato AAAA-MM.');
        }
    
        const monthNames = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];
    
        const [, monthNumber] = dateString.split('-');   
        const monthIndex = parseInt(monthNumber, 10) - 1;
    
        if (monthIndex < 0 || monthIndex >= monthNames.length) {
            throw new Error('Número do mês inválido.');
        }
            return monthNames[monthIndex];
    }


    const handleChange = (event) => {
      setSelectedOption(event.target.value);
      if(event.target.value === 'todos'){
        setSelectedOptionLabel("Total")
      } else{
        const res = getMonthName(event.target.value)
        setSelectedOptionLabel(res)
      }
    };

    useEffect(() => {
        async function getGraphics() {
            await api.get("/graphics/super/superbet", {
                headers: {
                    Authorization: `Bearer ${user.token}`,
                },
            }).then(response => {
                setData(response.data);
                setMeses(response.data.Meses);
                setLoading(false);
            });
        }
        getGraphics();
    }, [user.token]);
    



    const calculatePercentageChange = (current, previous) => {
        if (!previous) return 0; 
        return ((current - previous) / previous) * 100;
    };



    const getChartData = () => {
        const depositsChange = data?.GraphicDeposit?.length > 1 
            ? calculatePercentageChange(
                data.GraphicDeposit[data.GraphicDeposit.length - 1],
                data.GraphicDeposit[data.GraphicDeposit.length - 2]
            ) 
            : 0;
    
        const registrationsChange = data?.GraphicsRegistrations?.length > 1 
            ? calculatePercentageChange(
                data.GraphicsRegistrations[data.GraphicsRegistrations.length - 1],
                data.GraphicsRegistrations[data.GraphicsRegistrations.length - 2]
            ) 
            : 0;
    
        const withdrawalsChange = data?.GraphicsFTD?.length > 1 
            ? calculatePercentageChange(
                data.GraphicsFTD[data.GraphicsFTD.length - 1],
                data.GraphicsFTD[data.GraphicsFTD.length - 2]
            ) 
            : 0;
    
        const commissionChange = data?.GraphicsCommissionTotal?.length > 1 
            ? calculatePercentageChange(
                data.GraphicsCommissionTotal[data.GraphicsCommissionTotal.length - 1],
                data.GraphicsCommissionTotal[data.GraphicsCommissionTotal.length - 2]
            ) 
            : 0;
    
        const commissionChangeRev = data?.GraphicsREV?.length > 1 
            ? calculatePercentageChange(
                data.GraphicsREV[data.GraphicsREV.length - 1],
                data.GraphicsREV[data.GraphicsREV.length - 2]
            ) 
            : 0;
    
        const commissionChangeCPA = data?.GraphicsCPA?.length > 1 
            ? calculatePercentageChange(
                data.GraphicsCPA[data.GraphicsCPA.length - 1],
                data.GraphicsCPA[data.GraphicsCPA.length - 2]
            ) 
            : 0;
    
        const monthlyTotals = Array.isArray(data?.MonthlyTotals) ? data.MonthlyTotals : [];
    
        const filteredData = selectedOption !== "todos" 
            ? monthlyTotals.filter(item => item.Period === selectedOption) 
            : {};
    
        const formattedFilteredData = filteredData[0] || {};
    
        return [
            {
                id: 1,
                title:  `Depósitos ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.Deposits : data?.Total?.Deposits)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                perstangeValue: depositsChange.toFixed(2),
                bagdeColor: depositsChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "Depósitos",
                    data: data?.GraphicDeposit,
                    number: false,
                }],
                color: depositsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 2,
                title: `Registros ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.Registrations : data?.Total?.Registrations)?.toLocaleString(),
                perstangeValue: registrationsChange.toFixed(2),
                bagdeColor: registrationsChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "New Application",
                    data: data?.GraphicsRegistrations,
                    number: true,
                }],
                color: registrationsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 4,
                title: `FTDs ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.FirstDepositCount : data?.Total?.FirstDepositCount)?.toLocaleString('pt-BR'),
                perstangeValue: withdrawalsChange.toFixed(2),
                bagdeColor: withdrawalsChange >= 0 ? "success" : "danger",
                istrendingArrow: true,
                seriesData: [{
                    name: "Total Rejected",
                    data: data?.GraphicsFTD,
                    number: false,
                }],
                color: withdrawalsChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 5,
                title: `Comissão REV ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.PercentCommission : data?.Total?.PercentCommission)?.toLocaleString('pt-BR'),
                perstangeValue: commissionChangeRev.toFixed(2),
                bagdeColor: commissionChangeRev >= 0 ? "success" : "danger",
                istrendingArrow: true,
                seriesData: [{
                    name: "Total Rejected",
                    data: data?.GraphicsREV,
                    number: false,
                }],
                color: commissionChangeRev >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 6,
                title: `Comissão CPA ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.CPACommission : data?.Total?.CPACommission)?.toLocaleString('pt-BR'),
                perstangeValue: commissionChangeCPA.toFixed(2),
                bagdeColor: commissionChangeCPA >= 0 ? "success" : "danger",
                istrendingArrow: true,
                seriesData: [{
                    name: "Total Rejected",
                    data: data?.GraphicsCPA,
                    number: false,
                }],
                color: commissionChangeCPA >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            },
            {
                id: 3,
                title: `Comissão Total ${selectedOptionLabel}`,
                price: (selectedOption !== "todos" ? formattedFilteredData.TotalCommission : data?.Total?.TotalCommission)?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' }),
                perstangeValue: commissionChange.toFixed(2),
                bagdeColor: commissionChange >= 0 ? "success" : "danger",
                seriesData: [{
                    name: "Total Approved",
                    data: data?.GraphicsCommissionTotal,
                    number: false,
                }],
                color: commissionChange >= 0 ? '["--bs-success", "--bs-transparent"]' : '["--bs-danger", "--bs-transparent"]'
            }
        ];
    };
    

    const generateMonths = () => {
        if (!Array.isArray(meses)) {
            return [];
        }

        const monthNames = [
            'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
            'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
        ];

        return meses.map(month => {
            const [year, monthNumber] = month.split('-');
            const formattedMonth = monthNumber.padStart(2, '0'); 
            const monthLabel = monthNames[parseInt(monthNumber, 10) - 1]; 
            const label = `${monthLabel} ${year}`; 

            return { value: month, label }; 
        });
    };
    
    const chartsData = getChartData();
    const months = generateMonths();

   

    return (
        <React.Fragment>
                {loading ? (
                       <Loading />
                ) : (
            <div className="page-content">
                    <Container fluid style={{ marginTop: '-30px' }}>
                        <Section />
                        <Row>
                                <Col lg={8}>
                                </Col>
                                <Col lg={4}>
                                    <FormGroup>
                                    <Input
                                        type="select"
                                        name="select"
                                        id="selectOption"
                                        value={selectedOption}
                                        onChange={handleChange}
                                    >
                                            <option key="1" value={"todos"}>
                                                Todos
                                            </option>
                                        {months.map((month) => (<>
                                            <option key={month.value} value={month.value}>
                                                {month.label}
                                            </option>
                                        </>
                                        ))}
                                    </Input>
                                    </FormGroup>
                                </Col>
                                </Row>
                        <ChartSection chartsData={chartsData} />
                        <Row>
                            <StatisticsApplications Deposits={data?.GraphicDeposit} FTDs={data?.GraphicsFTDValue} Rev={data?.GraphicsREV} CPA={data?.GraphicsCPA} Commission={data?.GraphicsCommissionTotal} data={data}/>
                            <SalesAnalytics2 data={data.Total} dataDias={data.MonthlyTotals} dataColors='["--bs-primary","--bs-success","--bs-danger", "--bs-warning"]' dashboard={true} />
                        </Row>
                        <Row>
                        {/* <JobVacancy data={data} />  */}
                        <CandidateSection />
                        </Row>
                    </Container>
            </div>
                )}
        </React.Fragment>
    );
};

export default DashboardJob;
